import React, { useEffect, useState } from 'react'
import QRCodeScanner from './QRCodeScanner'
import prsService from '../../../service/prs'
import Swal from 'sweetalert2'

const PrsForm = ({ qrCodeData, setQrCodeData }) => {
  const [accordionStep, setAccordionStep] = useState(1)
  const [isStep1Complete, setIsStep1Complete] = useState(false)
  const [presets, setPresets] = useState([])
  const [isStep2Complete, setIsStep2Complete] = useState(false)
  const [isManualEntry, setIsManualEntry] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [selectedPreset, setSelectedPreset] = useState({})
  const [sampleData, setSampleData] = useState({
    barcode: '',
    presetId: '',
    clientInfo: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      sex: '',
    },
  })

  const getPresets = async () => {
    try {
      const res = await prsService.getPresets()
      setPresets(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPresets()
  }, [])

  const handleQrCodeData = async (value) => {
    Swal.fire({
      title: 'Checking Barcode...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      const checkBarcode = await prsService.checkBarcode(value)

      Swal.close()
      console.log(checkBarcode)
      if (checkBarcode.data.isValid) {
        Swal.fire({
          title: 'Barcode is Valid',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
        setAccordionStep(2)
        setQrCodeData(value)
        setIsStep1Complete(true)
      } else {
        Swal.fire({
          title: 'Barcode is Invalid',
          text: checkBarcode.data.message,
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } catch (error) {
      Swal.close()
      console.log(error)
      Swal.fire({
        title: 'Error Checking Barcode',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setSampleData((prev) => ({
      ...prev,
      clientInfo: {
        ...prev.clientInfo,
        [name]: value,
      },
    }))
  }

  const handleSubmit = async () => {
    try {
      sampleData.barcode = qrCodeData

      if (
        !sampleData.clientInfo.firstName ||
        !sampleData.clientInfo.lastName ||
        !sampleData.clientInfo.sex ||
        !sampleData.clientInfo.dateOfBirth ||
        !sampleData
      ) {
        Swal.fire({
          title: 'Error',
          text: 'Please fill all fields',
          icon: 'error',
          confirmButtonText: 'OK',
        })
        return
      }
      const req = await prsService.createPrs(sampleData)
      if (req.status === 201) {
        setIsCompleted(true)
      } else {
        Swal.fire({
          title: 'Error',
          text: req.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }

    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: 'OK',
      })

      console.log(error)
    }
  }
  return (
    <div className='row'>
      <div className='card card-custom'>
        <div className='card-body'>
          {!isCompleted && (
            <div className='accordion' id='kt_accordion_1'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                  <button
                    className={`accordion-button fs-4 fw-bold ${accordionStep === 1 ? '' : 'collapsed'
                      }`}
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_1'
                    aria-expanded={accordionStep === 1}
                    aria-controls='kt_accordion_1_body_1'
                    disabled={isStep1Complete}
                  >
                    {isStep1Complete || accordionStep > 1 ? (
                      <i className='fa fa-check-circle text-success mx-2'></i>
                    ) : (
                      ''
                    )}
                    Scan QR
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_1'
                  className={`accordion-collapse collapse ${accordionStep === 1 ? 'show' : ''}`}
                  aria-labelledby='kt_accordion_1_header_1'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <QRCodeScanner
                          qrCodeData={qrCodeData}
                          setQrCodeData={setQrCodeData}
                          setIsStep1Complete={setIsStep1Complete}
                          handleQrCodeData={handleQrCodeData}
                          setAccordionStep={setAccordionStep}
                        />
                      </div>
                      <div className='col-md-8'>
                        <h4>Scan Your QR Code</h4>
                        <p>
                          Position your QR code over the camera to complete the first step.
                        </p>
                        <ul>
                          <li><strong>Keep the Camera Steady:</strong> Hold your camera steady while scanning the QR code to ensure a clear image.</li>
                          <li><strong>Adequate Lighting:</strong> Make sure there is enough light on the QR code. Insufficient lighting may make scanning difficult.</li>
                          <li><strong>Full Code Visibility:</strong> The entire QR code should be visible on the camera screen. Avoid holding the code too far or too close.</li>
                          <li><strong>Camera Permission:</strong> If the scanner is within an app, ensure you have granted camera access.</li>
                          <li><strong>Code Clarity:</strong> If there are scratches or blurry areas on the QR code, it may not be readable. Ensure the code is clean and clear.</li>
                        </ul>
                        <button
                          className='btn btn-secondary'
                          onClick={() => setIsManualEntry(!isManualEntry)}
                        >
                          Enter Code Manually
                        </button>
                        {isManualEntry && (
                          <>
                            <input
                              className='form-control mt-3 mb-4'
                              placeholder='Enter Code'
                              onChange={(e) => setQrCodeData(e.target.value)}
                              value={qrCodeData}
                            />
                            <button
                              className='btn btn-primary'
                              onClick={() => handleQrCodeData(qrCodeData)}
                            >
                              Continue <i className='fa fa-arrow-down'></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='accordion-item mt-3'>
                <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                  <button
                    className={`accordion-button fs-4 fw-bold ${accordionStep === 2 ? '' : 'collapsed'
                      }`}
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_2'
                    aria-expanded={accordionStep === 2}
                    aria-controls='kt_accordion_1_body_2'
                    disabled={accordionStep < 2}
                  >
                    {isStep2Complete || accordionStep > 2 ? (
                      <i className='fa fa-check-circle text-success mx-2'></i>
                    ) : (
                      ''
                    )}
                    Template
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_2'
                  className={`accordion-collapse collapse ${accordionStep === 2 ? 'show' : ''}`}
                  aria-labelledby='kt_accordion_1_header_2'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='w-100 ml-2 mb-2 input-label'>Choose Template</label>
                        <select
                          className='form-select'
                          onChange={(e) => {
                            setSampleData((prev) => ({
                              ...prev,
                              presetId: e.target.value === '0' ? '' : e.target.value,
                            }))
                            if(e.target.value === '0') {
                              setSelectedPreset({})
                              return
                            }
                            setSelectedPreset(presets.find((preset) => preset.id === e.target.value))
                          }
                          }
                        >
                          <option value='0'>Select Template</option>
                          {presets.map((preset) => (
                            <option key={preset.id} value={preset.id}>
                              {preset.name}
                            </option>
                          ))}
                        </select>
                        {selectedPreset.id !== "" && selectedPreset.id !== undefined && (
                          <div className=''>

                            <h4 className='mb-0 mt-5'>{selectedPreset.preset_data?.title}</h4>
                            <ul className='d-flex align-items-center justify-content-between flex-wrap'>
                              {selectedPreset?.traits.map((item, index) => {
                                return (

                                  <li style={{
                                    width: '32%'
                                  }} className=" " key={index}>{item.name}</li>
                                )
                              })}
                            </ul>
                          </div>
                        )}
                        <button
                          className='btn btn-primary my-3 pull-right '
                          disabled={!sampleData.presetId}
                          onClick={() => {
                            setAccordionStep(3)
                            setIsStep2Complete(true)
                          }}
                        >
                          Continue <i className='fa fa-arrow-down'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='accordion-item mt-3'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className={`accordion-button fs-4 fw-bold ${accordionStep === 3 ? '' : 'collapsed'
                      }`}
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_3'
                    aria-expanded={accordionStep === 3}
                    aria-controls='kt_accordion_1_body_3'
                    disabled={accordionStep < 3}
                  >
                    Sample Information
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className={`accordion-collapse collapse ${accordionStep === 3 ? 'show' : ''}`}
                  aria-labelledby='kt_accordion_1_header_3'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label className='form-label fs-6 fw-bold'>Barcode</label>
                        <input
                          className='form-control mb-4'
                          placeholder='Sample ID'
                          value={qrCodeData}
                          disabled
                        />
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label fs-6 fw-bold'>Name</label>
                        <input
                          className='form-control mb-4'
                          placeholder='Name'
                          name='firstName'
                          value={sampleData.clientInfo.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label fs-6 fw-bold'>Surname</label>
                        <input
                          className='form-control mb-4'
                          placeholder='Surname'
                          name='lastName'
                          value={sampleData.clientInfo.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label fs-6 fw-bold'>Date of Birth</label>
                        <input
                          className='form-control mb-4'
                          type='date'
                          name='dateOfBirth'
                          value={sampleData.clientInfo.dateOfBirth}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-6'>
                        <label className='form-label fs-6 fw-bold'>Gender</label>
                        <select
                          className='form-select'
                          name='sex'
                          value={sampleData.clientInfo.sex}
                          onChange={handleInputChange}
                        >
                          <option value=''>Select Gender</option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                          <option value='n/a'>N/A</option>
                        </select>
                      </div>
                    </div>
                    <button
                      className='btn btn-primary my-3 mx-auto text-center d-flex gap-5 align-items-center'
                      onClick={handleSubmit}
                    >
                      Create <i className='fa fa-check'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isCompleted && (
            <div className='text-center'>
              <h3>Run Created Successfully</h3>
              <button
                className='btn btn-primary mt-3'
                onClick={() => {
                  setQrCodeData('')
                  setAccordionStep(1)
                  setIsStep1Complete(false)
                  setIsStep2Complete(false)
                  setIsCompleted(false)
                  setSampleData({
                    barcode: '',
                    presetId: '',
                    clientInfo: {
                      firstName: '',
                      lastName: '',
                      dateOfBirth: '',
                      sex: '',
                    },
                  })
                }}
              >
                Create Another Sample
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PrsForm
