import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import TraidChart from './TraidChart';
import LinkList from './LinkList';

const TraitDetailModal = ({
  isModalOpen,
  onClose,
  selectedPgsData,
  handleNext,
  handlePrevious,
  availableTraits
}) => {
  const [loading, setLoading] = useState(false);
  const [isNonNull, setIsNonNull] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [selectedPgsData]);

  const handleChartRendered = (isRendered) => {
    if (isRendered) {
      setLoading(false);
    }
  };

  function humanReadableKey(key) {
    const camelCaseToSpaces = key.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const snakeCaseToSpaces = camelCaseToSpaces.replace(/_/g, ' ');
    return snakeCaseToSpaces
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  if (!isModalOpen) {
    return null; // Modal kapalıyken hiçbir şey render etmez
  }
  return (
    <div className='custom-modal-backdrop'>
      <div className='custom-modal pt-1' style={{
        minWidth: '90%',
      }}>
        <div className='modal-content'>
          <div className='modal-header justify-content-end mb-2' style={{ padding: '0px 0px' }}>
            <span onClick={onClose} className='ms-2 cursor-pointer'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon ' />
            </span>
          </div>
          <div className='modal-body' style={{ paddingBottom: 0 }}>
            <div className='d-flex flex-column align-items-center mb-5'>
              <div className='d-flex justify-content-between w-100 align-items-center'>
                <h4>{humanReadableKey(selectedPgsData.metadata.trait_mapped)}</h4>
                <h4>
                  <span
                    className={`badge badge-${selectedPgsData.results.prs < 1
                      ? 'danger'
                      : selectedPgsData.PRS < 1.3
                        ? 'warning'
                        : 'success'
                      } fs-4`}
                  >
                    PRS : {selectedPgsData.results.prs.toFixed(3)}
                  </span>
                </h4>
              </div>
              <div className='pgs-meta w-100 row'>
                <div className="col-md-6">
                  <label className='text-muted mt-1 fw-semibold fs-7'> Score Information</label>
                  <div className='border w-100 border-gray-300 border-dashed rounded d-flex gap-5  py-3 px-4 me-7 mb-3'>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div className='fs-6 text-gray-800 fw-bolder'>{selectedPgsData?.metadata?.trait_reported}</div>
                      <div className='fw-bold text-gray-500 fs-8'>Trait Reported</div>
                    </div>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div className='fs-6 text-gray-800 fw-bolder'>{selectedPgsData?.metadata?.variants_number}</div>
                      <div className='fw-bold text-gray-500 fs-8'>Variants Number</div>
                    </div>
                    <div>
                      <div className='fs-6 text-gray-800 fw-bolder'>
                        <a href={`https://doi.org/${selectedPgsData?.metadata?.citation ? selectedPgsData?.metadata?.citation.split('doi:')[1] : ''}`} className=""> {selectedPgsData?.metadata?.citation ? selectedPgsData?.metadata?.citation.split('doi:')[0] : ''}  </a>
                      </div>
                      <div className='fw-bold text-gray-500 fs-8'>Citation</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className='text-muted mt-1 fw-semibold fs-7'> Catalog Information</label>
                  <div className='border border-gray-300 border-dashed rounded d-flex gap-5  py-3 px-4 me-7 mb-3'>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div className='fs-6 text-gray-800 fw-bolder'> <a href={`https://www.pgscatalog.org/publication/${selectedPgsData?.metadata?.pgp_id}`}> {selectedPgsData?.metadata?.pgp_id}</a></div>
                      <div className='fw-bold text-gray-500 fs-8'>PGP ID </div>
                    </div>
                    <div
                      style={{
                        borderRight: '1px solid #e0e0e0',
                        paddingRight: '10px',
                      }}
                    >
                      <div className='fs-6 text-gray-800 fw-bolder'>
                        <a href={`https://www.pgscatalog.org/score/${selectedPgsData?.metadata?.pgs_id}`} target='_blank' rel='noreferrer'>
                          {selectedPgsData?.metadata?.pgs_id}
                        </a>
                      </div>
                      <div className='fw-bold text-gray-500 fs-8'>PGS ID</div>
                    </div>
                    <div>
                      <div className='fs-6 text-gray-800 fw-bolder'>
                        <a href={`https://www.ebi.ac.uk/ols4/ontologies/efo/classes?short_form=${selectedPgsData?.metadata?.trait_efo}`} target='_blank' rel='noreferrer'>
                          {selectedPgsData?.metadata?.trait_efo}
                        </a>
                      </div>
                      <div className='fw-bold text-gray-500 fs-8'>Trait EFO</div>
                    </div>
                  </div>
                </div>
 
              </div>

            </div>
            <div className='row'>
              <div className='col-md-7'>
                <div className='form-check form-switch mb-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexSwitchCheckDefault'
                    checked={isNonNull}
                    onChange={() => setIsNonNull(!isNonNull)}
                  />
                  <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                    Sorted by Non Null Effect
                  </label>
                </div>
                {loading ? (
                  <div style={{ textAlign: 'center', padding: '50px' }}>
                    <div className='spinner-border text-primary' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <TraidChart
                    data={selectedPgsData}
                    type={'scatter'}
                    nonNull={isNonNull}
                    prsScore={selectedPgsData.results.prs.toFixed(3)}
                    onChartRendered={handleChartRendered}
                  />
                )}
              </div>
              <div className='col-md-5'>
                <LinkList
                  data={selectedPgsData.matches}
                  calcRiskScore={selectedPgsData.calc_risk_score}
                  genomeBuild={selectedPgsData?.metadata?.genome_build}
                  aleles={selectedPgsData.alleles}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer' style={{ padding: '2px 20px', paddingTop: '10px', paddingBottom: '10px' }}>
            {selectedPgsData?.results?.pgsId !== availableTraits[0] && (
              <button type='button' className='btn btn-primary' style={{ marginRight: 'auto' }} onClick={handlePrevious}>
                <i className='fa fa-chevron-left'></i> Back
              </button>
            )}
            {selectedPgsData?.results?.pgsId !== availableTraits[availableTraits.length - 1] && (
              <button type='button' className='btn btn-primary' onClick={handleNext}>
                Next <i className='fa fa-chevron-right'></i>
              </button>
            )}

            <button type='button' className='btn btn-light' onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraitDetailModal;
