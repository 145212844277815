import React from 'react'

const ReportButtons = ({getReport}) => {
  return (
    <div className='card px-5'>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2'>
        <h3 className='card-title align-items-start flex-column'></h3>
      </div>
      <div className='card-body pt-0 px-2 d-flex justify-content-center flex-column gap-3'>
        <button className='btn btn-primary mx-2'>JSON</button>
        <button className='btn btn-secondary mx-2' onClick={() => getReport()}>
          Report
        </button>
      </div>
    </div>
  )
}

export {ReportButtons}
