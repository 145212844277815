import {useEffect, useState} from 'react'
import prsService from '../service/prs'

const useAdvencedFiltersPrs = () => {
  const [runsDatas, setRunsDatas] = useState([])
  const [initialData, setInitialData] = useState([])
  const [tableIsLoading, setTableIsLoading] = useState(true)

  const getData = async () => {
    setTableIsLoading(true)
    const getAllRuns = await prsService.getAllPrs()
    const result = []
    if (getAllRuns.status === 200) {
      console.log(getAllRuns?.data)
      setRunsDatas(getAllRuns?.data ?? [])
      setInitialData(result)
      setTableIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    getData,
    runsDatas,
    tableIsLoading,
  }
}

export {useAdvencedFiltersPrs}
