import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import debounce from "lodash.debounce";
import somaticService from "../../../service/somatic";

const ClinicalTrialsWidget = ({ gene }) => {
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedPhases, setSelectedPhases] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [clinicalTrials, setClinicalTrials] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const pageSize = 20;
    const maxVisible = 8;
    const humanReadableStatus = (text) => {
        if (!text) return "";
        return text
            .toLowerCase()
            .split(/[_|]/)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) 
            .join(" "); 
    };
    const dropdownRef = useRef(null);

    // Debounced API call
    const fetchConditions = debounce(async (query) => {
        if (query && query.length > 2) {
            try {
                const response = await somaticService.getClinicalConditions(query);
                setSearchResults(response.data);
            } catch (error) {
                console.error("Error fetching conditions:", error);
            }
        } else {
            setSearchResults([]);
        }
    }, 1000);

    const fetchClinicalTrials = async () => {
        if (isFetching) return;
        if (!gene) {
            setClinicalTrials([]);
            return;
        }
        const data = {
            geneName: gene,
            clinicalConditions: selectedConditions.map((c) => c.clinicalCondition),
            phases: selectedPhases.map((p) => p.value),
            status: selectedStatuses.map((s) => s.value),
            page,
            pageSize,
        };

        setIsFetching(true);
        try {
            const response = await somaticService.getClinicalTrials(data);
            setClinicalTrials(response.data);
        } catch (error) {
            console.error("Error fetching clinical trials:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleSubmitFilters = () => {
        setPage(1);
        fetchClinicalTrials();
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        if (gene) {
            setSelectedConditions([]);
            setSelectedPhases([]);
            setSelectedStatuses([]);
            setPage(1);
            fetchClinicalTrials();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gene]);

    useEffect(() => {

        if (gene && page > 1) {
            fetchClinicalTrials();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleInputChange = (value) => {
        setSearchInput(value);
        fetchConditions(value);
    };

    const handleClearInput = () => {
        setSearchInput("");
        setSearchResults([]);
    };

    const handleConditionSelect = (condition) => {
        if (!selectedConditions.some((item) => item.clinicalCondition === condition.clinicalCondition)) {
            setSelectedConditions([...selectedConditions, condition]);
        }
    };

    const handleConditionRemove = (condition) => {
        setSelectedConditions(
            selectedConditions.filter(
                (item) => item.clinicalCondition !== condition.clinicalCondition
            )
        );
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const phaseOptions = [
        { value: "NA", label: "Not Available" },
        { value: "PHASE1", label: "Phase1" },
        { value: "PHASE2", label: "Phase2" },
        { value: "EARLY_PHASE1", label: "Early Phase1" },
        { value: "PHASE3", label: "Phase3" },
        { value: "PHASE4", label: "Phase4" },
        { value: "PHASE1|PHASE2", label: "Phase1|Phase2" },
        { value: "PHASE2|PHASE3", label: "Phase2|Phase3" },
    ];

    const statusOptions = [
        { value: "COMPLETED", label: "Completed" },
        { value: "WITHDRAWN", label: "Withdrawn" },
        { value: "UNKNOWN", label: "Unknown" },
        { value: "ACTIVE_NOT_RECRUITING", label: "Active not Recruiting" },
        { value: "RECRUITING", label: "Recruiting" },
        { value: "ENROLLING_BY_INVITATION", label: "Enrolling by Invitation" },
        { value: "TERMINATED", label: "Terminated" },
        { value: "NOT_YET_RECRUITING", label: "Not yet Recruiting" },
        { value: "SUSPENDED", label: "Suspended" },
        { value: "NO_LONGER_AVAILABLE", label: "No Longer Available" },
        { value: "APPROVED_FOR_MARKETING", label: "Approved For Marketing" },
        { value: "AVAILABLE", label: "Available" },
        { value: "WITHHELD", label: "Withheld" },
        { value: "TEMPORARILY_NOT_AVAILABLE", label: "Temporarily not Available" },
    ];

    return (
        <div className="card mb-3">
            <div className="card-body p-3 pb-0">
                <div className="d-flex align-items-center justify-content-between mb-2 py-1">
                    <h5 className="card-title mb-0">Results</h5>
                    <i
                        className="fa fa-cog ms-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    ></i>
                </div>
                {isDropdownOpen && (
                    <div
                        className="custom-dropdown bg-white border shadow p-3"
                        ref={dropdownRef}
                        style={{ position: "absolute", zIndex: 1000, width: "100%", left: 0 }}
                    >
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder="Search conditions..."
                                value={searchInput}
                                onChange={(e) => handleInputChange(e.target.value)}
                            />
                            {searchInput && (
                                <button
                                    className="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 mt-2 me-2"
                                    onClick={handleClearInput}
                                >
                                    X
                                </button>
                            )}
                            {searchResults.length > 0 && (
                                <ul className="list-group mb-2" style={{ maxHeight: "125px", overflowY: "auto" }}>
                                    {searchResults.map((result) => (
                                        <li
                                            key={result.clinicalCondition}
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                            onClick={() => handleConditionSelect(result)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {result.clinicalCondition} ({result.trialCount})
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {selectedConditions.length > 0 && (
                                <div className="mb-3">
                                    {selectedConditions.map((condition) => (
                                        <span
                                            key={condition.clinicalCondition}
                                            className="badge bg-primary text-white me-2 mb-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleConditionRemove(condition)}
                                        >
                                            {condition.clinicalCondition} ({condition.trialCount}) x
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label>Phase:</label>
                            <Select
                                options={phaseOptions}
                                isMulti
                                onChange={setSelectedPhases}
                                value={selectedPhases}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Status:</label>
                            <Select
                                options={statusOptions}
                                isMulti
                                onChange={setSelectedStatuses}
                                value={selectedStatuses}
                            />
                        </div>
                        <button className="btn btn-primary w-100 mt-2" onClick={handleSubmitFilters}>
                            Apply Filters
                        </button>
                    </div>
                )}
                {isFetching ? (
                    <div className={`h-auto text-center py-2`}>
                        <div className="spinner-grow text-primary m-auto py-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="mt-4">
                        {clinicalTrials.map((trial, index) => (
                            <div key={index} className="mb-3 p-3 border rounded bg-light">
                                <div className="d-flex align-items-center  mb-2">
                                    <a href={`https://clinicaltrials.gov/study/${trial.nctId}`} className="text-muted" target="_blank" rel="noreferrer"><h5 className="card-title  mb-0">{trial.nctId}</h5> </a>
                                    <span className={`badge bg-${trial.status === 'RECRUITING' || trial.status === 'NOT_YET_RECRUITING' ? 'success' : trial.status === 'UNKNOWN' ? 'warning' : 'danger'} text-white ms-3`}>{humanReadableStatus(trial.status)}</span>
                                    {trial.isNew && <span className="badge bg-brand text-white ms-2">NEW</span>}
                                </div>
                                <h6 className="card-subtitle my-2 fs-8">{trial.briefTitle}</h6>
                                <hr style={{
                                    borderColor: '#c8c8c8',
                                }} />
                                <div className="mt-3">
                                    <div className="mb-1 ">
                                        <div className="">
                                            <strong className="mb-1">Conditions: </strong>
                                            {trial.conditionList.slice(0, maxVisible).map((condition, i) => (
                                                <span key={i} className="badge bg-secondary m-1">{condition}</span>
                                            ))}
                                            {trial.conditionList.length > maxVisible && (
                                                <>
                                                    <br />
                                                    <a
                                                        href={`https://clinicaltrials.gov/study/${trial.nctId}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="text-white btn btn-primary d-inline-block p-2 fs-8"
                                                    >
                                                        Show All
                                                    </a>
                                                </>
                                            )}
                                        </div>

                                    </div>
                                    <hr style={{
                                        borderColor: '#c8c8c8',
                                    }} />
                                    <p className="mb-0 fs-8">
                                        <strong>Locations:</strong> {trial.location || "Location not provided"}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {clinicalTrials.length === 0 && !isFetching && (
                    <div className="text-center py-3">No clinical trials found.</div>
                )}
                <div className="d-flex justify-content-between mt-4 pb-2">
                    <button
                        className="btn btn-secondary"
                        disabled={page === 1 || isFetching}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    >
                        Previous
                    </button>
                    {clinicalTrials.length > 0 && (
                        <button
                            className="btn btn-secondary"
                            disabled={isFetching}
                            onClick={() => setPage((prev) => prev + 1)}
                        >
                            Next
                        </button>

                    )}

                </div>
            </div>
        </div >
    );
};

export default ClinicalTrialsWidget;
