/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import runsService from '../../service/runs'

import {useIntl} from 'react-intl'
type Props = {
  comment: any
  runId: any
}

const Comments: React.FC<Props> = ({comment, runId}) => {
  const intl = useIntl()
  const [editMode, setEditMode] = React.useState(false)

  const [runComment, setComment] = React.useState(comment)

  const [updatedComment, setUpdatedComment] = React.useState(comment)

  const changeEditMode = () => {
    setEditMode(!editMode)
  }
  const onChangeComment = (event) => {
    setUpdatedComment(event.target.value)
  }

  const saveComment = () => {
    runsService.updateRunComment(updatedComment, runId)
    setComment(updatedComment)
    setEditMode(!editMode)
  }

  return (
    //  Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'COMMENTS'})}</span>
        </h3>
      </div>
      <div className='card-body py-0 px-2 '>
        {editMode && (
          <div className='fv-row mb-10'>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder='Comment'
              rows={8}
              onChange={onChangeComment}
            >
              {comment}
            </textarea>
          </div>
        )}

        {!editMode && (
          <div className='p-5 rounded bg-light-info text-dark fw-bold text-start'>{runComment}</div>
        )}
      </div>
      <div className='card-footer d-flex justify-content-end px-0'>
        {editMode && (
          <div>
            <button onClick={changeEditMode} className='btn  btn-secondary mx-2'>
            <i className='fa fa-arrow-left text-dark '></i> Cancel 
            </button>
            <button onClick={saveComment} className='btn btn-primary'>
              Save <i className='fa fa-save'></i>
            </button>
          </div>
        )}

        {!editMode && (
          <button onClick={changeEditMode} className='btn btn-secondary'>
            Edit <i className='fa fa-edit'></i>
          </button>
        )}
      </div>
    </div>
    //  Card End
  )
}

export {Comments}
