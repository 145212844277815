/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Loader } from '../../components/Loader'
import { TraidList } from '../../components/PrsRunDetail/TraidList'
import TraitDetailModal from '../../components/PrsRunDetail/TraitDetailModal'
import prsService from '../../service/prs'
import { PRSNotCompleted } from '../../components/PrsRunDetail/PRSNotCompleted'
import { ClientInformation } from '../../components/PrsRunDetail/ClientInformation'
import { PrsInformation } from '../../components/PrsRunDetail/PrsInformation'
import { ReportButtons } from '../../components/PrsRunDetail/ReportButtons'
import Swal from 'sweetalert2'

const PrsRunDetailPage = () => {
  const intl = useIntl()
  const { id } = useParams()
  const [runData, setRunData] = useState({})
  const [selectedTraidList, setSelectedTraidList] = useState([])
  const [runTraits, setRunTraits] = useState([])
  const [availableTraits, setAvailableTraits] = useState([])
  const [selectedPgsData, setSelectedPgsData] = useState(null)
  const [requestedPgs, setRequestedPgs] = useState({})
  const [currentTraitIndex, setCurrentTraitIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getData = async () => {
    const getRunDetail = await prsService.getPrsDetail(id)
    if (getRunDetail.status === 200) {
      setRunData(getRunDetail.data)
      if (getRunDetail.data.status !== 'Completed') {
        setIsLoading(false)
        return
      }
      const baseUrl = `https://${getRunDetail.data.onNode}.node.gennext.bio/api/v0/prs/runs/${id}`
      const getTraits = await prsService.getTraits(baseUrl)
      if (getTraits.status === 200) {
        setRunTraits(getTraits.data)
        setIsLoading(false)
      }
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const getAvailableTraits = async (row) => {
    Swal.fire({
      title: 'Loading',
      html: 'Please wait...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
    })
    const baseUrl = `https://${runData.onNode}.node.gennext.bio/api/v0/prs/runs/${id}/trait?trait_id=${row.id}`
    const getTraitById = await prsService.getTraits(baseUrl)
    if (getTraitById.status === 200) {
      setAvailableTraits(getTraitById.data)
      if (getTraitById.data.length > 0) {
        await fetchPgsData(getTraitById.data[0], 0)
        setIsModalOpen(true)
        Swal.close()
      }
    }
  }

  const fetchPgsData = async (pgsId, traitIndex) => {
    Swal.fire({
      title: 'Loading',
      html: 'Please wait...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
    })
    if (requestedPgs[pgsId]) {
      setSelectedPgsData(requestedPgs[pgsId])
    } else {
      const baseUrl = `https://${runData.onNode}.node.gennext.bio/api/v0/prs/runs/${id}/pgs?pgs_id=${pgsId}`
      const getPgsData = await prsService.getTraits(baseUrl)
      if (getPgsData.status === 200) {
        setSelectedPgsData(getPgsData.data)
        setRequestedPgs((prev) => ({ ...prev, [pgsId]: getPgsData.data }))
        setCurrentTraitIndex(traitIndex)
      }
    }
  }

  const handleNext = async () => {
    const nextIndex = currentTraitIndex + 1
    if (nextIndex < availableTraits.length) {
      const nextPgsId = availableTraits[nextIndex]

      Swal.fire({
        title: 'Loading',
        html: 'Please wait...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        },
      })

      if (requestedPgs[nextPgsId]) {
        setSelectedPgsData(requestedPgs[nextPgsId])
        setCurrentTraitIndex(nextIndex)
        Swal.close()
      } else {
        await fetchPgsData(nextPgsId, nextIndex)
        Swal.close()
      }
    }
  }

  const handlePrevious = async () => {
    const prevIndex = currentTraitIndex - 1
    if (prevIndex >= 0) {
      const prevPgsId = availableTraits[prevIndex]

      Swal.fire({
        title: 'Loading',
        html: 'Please wait...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        },
      })

      if (requestedPgs[prevPgsId]) {
        setSelectedPgsData(requestedPgs[prevPgsId])
        setCurrentTraitIndex(prevIndex)
        Swal.close()
      } else {
        await fetchPgsData(prevPgsId, prevIndex)
        Swal.close()
      }
    }
  }
  const getReport = async () => {
    try {
      const language = await new Promise((resolve) => {
        Swal.fire({
          title: 'Select Report Language',
          html: `
            <button id="btn-tr" class="swal2-confirm btn btn-primary" style="margin: 0 10px;">TR</button>
            <button id="btn-en" class="swal2-cancel btn bg-brand text-white" style="margin: 0 10px;">EN</button>
          `,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: true,
          customClass: {
            closeButton: 'shadow-none',
          },
          didOpen: () => {
            document.getElementById('btn-tr').addEventListener('click', () => resolve('TR'));
            document.getElementById('btn-en').addEventListener('click', () => resolve('EN'));
          },
          willClose: () => resolve(null),
        });
      });

      if (!language) return;

      Swal.fire({
        title: 'Generating',
        html: 'Please wait...',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const baseUrl = `https://${runData.onNode}.node.gennext.bio/api/v0/prs/runs/${id}/report?lang=${language}`;
      const response = await prsService.getReport(baseUrl);

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('An error occurred:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while fetching the report.',
      });
    } finally {
      Swal.close();
    }
  };


  if (runData?.status !== 'Completed') {
    return (
      <>
        <PRSNotCompleted runData={runData} />
      </>
    )
  }

  if (isLoading) {
    return (
      <div className='text-center'>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'RUN.DETAIL' })}</PageTitle>
      <div className='row'>
        <div className='col-xl-12'>
          <Link className='btn btn-primary mb-2' to='/runs'>
            Back
          </Link>

          <div
            className='my-2 runDetailHeader'
            style={{
              gridTemplateColumns: '1fr 1fr 1fr',
            }}
          >
            <ClientInformation client={runData.productItems[0].client} />
            <PrsInformation
              genomeBuild={runData.genomeBuild}
              productItem={runData.productItems[0]}
            />
            <ReportButtons getReport={getReport} />
          </div>
          <div className='my-4 runDetailFooter'>
            <TraidList
              runTraits={runTraits.traits}
              getAvailableTraits={getAvailableTraits}
              selectedTraidList={selectedTraidList}
              setSelectedTraidList={setSelectedTraidList}
            />
            {isModalOpen && selectedPgsData && (
              <TraitDetailModal
                selectedPgsData={selectedPgsData}
                isModalOpen={isModalOpen}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                availableTraits={availableTraits}
                onClose={() => setIsModalOpen(false)} // Modal kapatma işlevi
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { PrsRunDetailPage }
