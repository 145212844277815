import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import TraidChart from '../../../components/PrsRunDetail/TraidChart'
import LinkList from '../../../components/PrsRunDetail/LinkList'
import DigitStep from './DigitStep'
import { run } from 'node:test'

type Metadata = {
    trait_mapped: string
    [key: string]: any
}

type Result = {
    prs: number
}

type Match = {
    varWeight: number
    genotypeChr: number
    genotypePos: number
    rsId: string
    varRef: string
    varAlt: string
}

type SelectedPgsData = {
    metadata: Metadata
    results: Result
    matches: Match[]
    alleles: string[]
    calc_risk_score: number
}

const GuestPrs: React.FC = () => {
    const { id } = useParams<{ id: any }>()
    const [loading, setLoading] = useState(true)
    const [selectedPgsData, setSelectedPgsData] = useState<SelectedPgsData | null>(null)
    const [isNonNull, setIsNonNull] = useState(false)
    const [availableTraits, setAvailableTraits] = useState<string[]>([])
    const [currentTraitIndex, setCurrentTraitIndex] = useState(0)
    const [onNode, setOnNode] = useState<string | null>(null)
    const [step, setStep] = useState(1)
    const [runDetail, setRunDetail] = useState<any>(null)
    const [digitInput, setDigitInput] = useState<string[]>(Array(8).fill(''))

    const token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzYzQxYmFjZC0yMmE2LTQ2MzktYWIxNS0yZmM5MTQxYThkNGYiLCJpYXQiOjE3MzE2NTQzNzUsImV4cCI6MTczMTY5NzU3NX0.b7zuZ2DREa2-AuGKoIYB6K9vT9zUg7CxpFi4dFLk9pU'
    // const id = '5d3774b1-6eb3-4a2a-9d8f-2428844091cc'

    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    const fetchRunDetail = async () => {
        Swal.fire({
            title: 'Loading Run Details',
            html: 'Please wait...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        });
        const productId = id.split('&')[0]
        const traitId = id.split('&')[1]
        const runDetailUrl = `https://gennext-edge.farplane.dev/api/v0/prs/report/${productId}`
        try {
            // const runDetailResponse = await axiosInstance.get(runDetailUrl)
            const runDetailResponse = await axiosInstance.get(runDetailUrl, {
                headers: {
                    Authorization: `Bearer ${digitInput.join("")
                        }`, // Token'inizi buraya koyun
                },
            });
            if (runDetailResponse.status === 200) {
                setOnNode(runDetailResponse.data.onNode)
                setRunDetail(runDetailResponse.data)
                const baseUrl = `https://${runDetailResponse.data.onNode}.node.gennext.bio/api/v0/prs/report/${productId}/trait`
                const getTraits = await axiosInstance.post(baseUrl, {
                    userId: runDetailResponse.data.ownerUserId,
                    runId: runDetailResponse.data.id,
                    traitId: traitId,
                }, {
                    headers: {
                        Authorization: `Bearer ${digitInput.join("")
                            }`, // Token'inizi buraya koyun
                    },
                })
                setAvailableTraits(getTraits.data)

                // if (getTraits.status === 200) {
                //     const availableTraitsUrl = `https://${runDetailResponse.data.onNode}.node.gennext.bio/api/v0/prs/runs/${id}/trait?trait_id=${traitId}`
                //     const getTraitsss = await axiosInstance.get(availableTraitsUrl, {
                //         headers: {
                //             Authorization: `Bearer ${digitInput.join("")
                //                 }`, // Token'inizi buraya koyun
                //         },
                //     })
                //     setAvailableTraits(getTraitsss.data)
                // }
                setLoading(false)

                Swal.close()
            }
        } catch (error: any) {
            console.log(error)
            setLoading(false)
            setStep(1)
            setDigitInput(Array(8).fill(''))
            Swal.fire('Error', error.response.data, 'error');
            console.error(error)
        }
    }

    const fetchPgsData = async (pgsId: string, traitIndex: number) => {
        if (!onNode) return

        Swal.fire({
            title: 'Loading Trait Data',
            html: 'Please wait...',
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
                Swal.showLoading()
            },
        })
        const productId = id.split('&')[0]
        // const traitId = id.split('&')[1]
        const baseUrl = `https://${onNode}.node.gennext.bio/api/v0/prs/report/${productId}/pgs`

        try {
            const getPgsData = await axiosInstance.post(baseUrl, {
                userId: runDetail.ownerUserId,
                runId: runDetail.id,
                pgsId: pgsId,
            }, {
                headers: {
                    Authorization: `Bearer ${digitInput.join("")
                        }`, // Token'inizi buraya koyun
                },
            })
            if (getPgsData.status === 200) {
                setSelectedPgsData(getPgsData.data)
                setCurrentTraitIndex(traitIndex)
                Swal.close()
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to load trait data', 'error')
            console.error(error)
        }
    }

    useEffect(() => {
        if (step === 2) {
            fetchRunDetail()
        }
    }, [step])

    useEffect(() => {
        if (runDetail && availableTraits.length > 0) {
            fetchPgsData(availableTraits[0], 0)
        }
    }, [runDetail, availableTraits])

    const handleNext = async () => {
        const nextIndex = currentTraitIndex + 1
        if (nextIndex < availableTraits.length) {
            const nextPgsId = availableTraits[nextIndex]
            fetchPgsData(nextPgsId, nextIndex)
        }
    }

    const handlePrevious = async () => {
        const prevIndex = currentTraitIndex - 1
        if (prevIndex >= 0) {
            const prevPgsId = availableTraits[prevIndex]
            fetchPgsData(prevPgsId, prevIndex)
        }
    }
    if (step === 1) {
        const handleInputChange = (index: number, value: string) => {
            setDigitInput((prev) => prev.map((val, idx) => (idx === index ? value : val)))
        }

        return (
            <DigitStep digitInput={digitInput} handleInputChange={handleInputChange} setStep={setStep} />
        )
    }

    if (loading || !selectedPgsData) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <div className='spinner-border text-primary' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div
            className=' d-flex justify-content-center align-items-center'
            style={{
                height: 'auto',
                paddingTop: '50px',
                paddingBottom: '50px',
            }}
        >
            <div className='card pgs-card'>
                <div className='d-flex justify-content-between align-items-center mb-5 report-footer-header'>
                    <h4>{selectedPgsData?.metadata?.trait_mapped}</h4>
                    <h4>
                        <span
                            className={`badge badge-${selectedPgsData?.results.prs < 1
                                ? 'danger'
                                : selectedPgsData?.results.prs < 1.3
                                    ? 'warning'
                                    : 'success'
                                } fs-4`}
                        >
                            PRS: {selectedPgsData?.results.prs.toFixed(3)}
                        </span>
                    </h4>
                </div>


                <div className='w-100 row'>
                    <div className="col-md-6">
                        <label className='text-muted mt-1 fw-semibold fs-7'> Score Information</label>
                        <div className='border w-100 border-gray-300 border-dashed rounded d-flex gap-5  py-3 px-4 mb-3'>
                            <div
                                style={{
                                    borderRight: '1px solid #e0e0e0',
                                    paddingRight: '10px',
                                }}
                            >
                                <div className={`fs-6 text-gray-800 fw-bolder text-truncate`} style={{
                                    width: '100px'
                                }}>{selectedPgsData?.metadata?.trait_reported}</div>
                                <div className='fw-bold text-gray-500 fs-8'>Trait Reported</div>
                            </div>
                            <div
                                style={{
                                    borderRight: '1px solid #e0e0e0',
                                    paddingRight: '10px',
                                }}
                            >
                                <div className='fs-6 text-gray-800 fw-bolder'>{selectedPgsData?.metadata?.variants_number}</div>
                                <div className='fw-bold text-gray-500 fs-8 text-truncate'>Variants Number</div>
                            </div>
                            <div>
                                <div className={`fs-6 text-gray-800 fw-bolder text-truncate`} style={{
                                    width: '100px'
                                }}>
                                    <a href={`https://doi.org/${selectedPgsData?.metadata?.citation ? selectedPgsData?.metadata?.citation.split('doi:')[1] : ''}`} className=""> {selectedPgsData?.metadata?.citation ? selectedPgsData?.metadata?.citation.split('doi:')[0] : ''}  </a>
                                </div>
                                <div className='fw-bold text-gray-500 fs-8 '>Citation</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className='text-muted mt-1 fw-semibold fs-7'> Catalog Information</label>
                        <div className='border border-gray-300 border-dashed rounded d-flex gap-5  py-3 px-4 mb-3'>
                            <div
                                style={{
                                    borderRight: '1px solid #e0e0e0',
                                    paddingRight: '10px',
                                }}
                            >
                                <div className='fs-6 text-gray-800 fw-bolder'> <a href={`https://www.pgscatalog.org/publication/${selectedPgsData?.metadata?.pgp_id}`}> {selectedPgsData?.metadata?.pgp_id}</a></div>
                                <div className='fw-bold text-gray-500 fs-8'>PGP ID </div>
                            </div>
                            <div
                                style={{
                                    borderRight: '1px solid #e0e0e0',
                                    paddingRight: '10px',
                                }}
                            >
                                <div className='fs-6 text-gray-800 fw-bolder'>
                                    <a href={`https://www.pgscatalog.org/score/${selectedPgsData?.metadata?.pgs_id}`} target='_blank' rel='noreferrer'>
                                        {selectedPgsData?.metadata?.pgs_id}
                                    </a>
                                </div>
                                <div className='fw-bold text-gray-500 fs-8'>PGS ID</div>
                            </div>
                            <div>
                                <div className='fs-6 text-gray-800 fw-bolder'>
                                    <a href={`https://www.ebi.ac.uk/ols4/ontologies/efo/classes?short_form=${selectedPgsData?.metadata?.trait_efo}`} target='_blank' rel='noreferrer'>
                                        {selectedPgsData?.metadata?.trait_efo}
                                    </a>
                                </div>
                                <div className='fw-bold text-gray-500 fs-8'>Trait EFO</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-7'>
                        <div className='form-check form-switch mb-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='flexSwitchCheckDefault'
                                checked={isNonNull}
                                onChange={() => setIsNonNull(!isNonNull)}
                            />
                            <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
                                Sorted by Non Null Effect
                            </label>
                        </div>
                        <TraidChart
                            data={selectedPgsData}
                            type={'scatter'}
                            nonNull={isNonNull}
                            prsScore={selectedPgsData?.results.prs.toFixed(3)}
                        />
                    </div>
                    <div className='col-md-5'>
                        <LinkList
                            data={selectedPgsData?.matches}
                            calcRiskScore={selectedPgsData?.calc_risk_score}
                            aleles={selectedPgsData?.alleles}
                            genomeBuild={selectedPgsData?.metadata?.genome_build}
                        />
                    </div>
                </div>

                <div className={`d-flex justify-content-${currentTraitIndex > 0 ? 'between' : 'end'} mt-3 report-footer-buttons `}>
                    {currentTraitIndex > 0 && (
                        <button type='button' className='btn btn-primary' onClick={handlePrevious}>
                            <i className='fa fa-chevron-left'></i> Previous
                        </button>
                    )}
                    {currentTraitIndex < availableTraits.length - 1 && (
                        <button type='button' className='btn btn-primary' onClick={handleNext}>
                            Next <i className='fa fa-chevron-right'></i>
                        </button>
                    )}
                </div>
            </div>
        </div >
    )
}

export default GuestPrs
