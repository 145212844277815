/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from '../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { PRSNotCompletedWidget } from '../../_metronic/partials/widgets/mixed/PRSNotCompletedWidget'
import { useEffect, useState } from 'react'
import NodeService from '../../service/node'
import { MD5 } from 'crypto-js'
import Swal from 'sweetalert2'
import prsService from '../../service/prs'

type Props = {
  runData: any
}
const PRSNotCompleted: React.FC<Props> = ({ runData }) => {
  const intl = useIntl()
  const [nodesList, setNodesList] = useState({})
  const [selectedNode, setSelectedNode] = useState<{ nodeName: string } | null>(null)
  const [selectedFile, setSelectedFile] = useState<{ name?: string }>({})
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    NodeService.getNodeList()
      .then((nodes) => nodes.sort((a, b) => b.isUp - a.isUp))
      .then((r) => Object.fromEntries(r.map((rr) => [MD5(JSON.stringify(rr)).toString(), rr])))
      .then((data) => {
        setNodesList(data)
      })
  }, [])
  useEffect(() => {
    if (isUploading && selectedNode && selectedFile) {
      handlePRSUpload()
      setIsUploading(false) // Yükleme işlemi tamamlandıktan sonra `isUploading` durumunu sıfırlayın
    }
  }, [isUploading, selectedNode, selectedFile]) // Yükleme durumu, seçilen dosya veya seçilen node değiştiğinde çalışır

  const handlePRSUpload = async () => {
    if (!selectedNode?.nodeName) {
      Swal.fire('Please select a node')
      return
    }
    if (!selectedFile?.name) {
      Swal.fire('Please select a file')
      return
    }
    try {
      const baseUrl = `https://${selectedNode.nodeName}.node.gennext.bio/api/v0/prs/runs/${runData.id}/upload`
      const data = {
        file: selectedFile,
        sampleId: runData.productItems[0].id,
      }
      Swal.fire({
        title: 'Uploading...',
        html:
          '<div id="progress-container" style="width: 100%; background-color: #f3f3f3; height: 20px; border-radius: 5px; overflow: hidden;">' +
          '<div id="progress-bar" style="width: 0; height: 100%; background-color: #4caf50;"></div>' +
          '</div>' +
          '<p id="progress-text" style="margin-top: 10px;">0%</p>',
        showConfirmButton: false,
        allowOutsideClick: false,
      })

      const req = await prsService.uploadPRSFile(baseUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          // İlerleme çubuğunu güncelle
          const progressBar = document.getElementById('progress-bar')
          const progressText = document.getElementById('progress-text')
          if (progressBar && progressText) {
            progressBar.style.width = `${progress}%`
            progressText.innerText = `${progress}%`
          }
          if (progress === 100) {
          }
        },
      })

      if (req.status === 200) {
        Swal.close()
        Swal.fire('File uploaded successfully')
        const startRun = await prsService.startRun(runData?.id, selectedNode.nodeName)
        window.location.reload()
      }

    } catch (error: any) {
      Swal.close()
      Swal.fire(error?.message || 'File uploaded failed')
    }


  }

  const handleUploadClick = () => {
    if (!selectedNode?.nodeName) {
      Swal.fire('Please select a node')
      return
    }
    if (!selectedFile) {
      Swal.fire('Please select a file')
      return
    }
    setIsUploading(true)
  }

  if (Object.keys(runData).length === 0) return null

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'RUN.DETAIL' })}</PageTitle>
      <div className='row'>
        <div className='col-xl-12'>
          <Link className='btn btn-primary mb-2' to='/runs'>
            Back
          </Link>
          {!!runData && (
            <PRSNotCompletedWidget
              selectedFile={selectedFile}
              handleUploadClick={handleUploadClick}
              setSelectedFile={setSelectedFile}
              setSelectedNode={setSelectedNode}
              selectedNode={selectedNode}
              nodesList={nodesList}
              runData={runData}
              className='card-xl-stretch mb-xl-8'
            />
          )}
        </div>
      </div>
    </>
  )
}

export { PRSNotCompleted }
