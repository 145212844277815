import React, { useMemo, useState } from 'react'
import DataTable from 'react-data-table-component-with-filter'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Loader } from './Loader'
import RunsService from '../service/runs'
import { useAdvencedFiltersPrs } from '../hooks/useAdvencedFiltersPrs'

const PRSRunsTable = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {
    runsDatas,
    tableIsLoading,
  } = useAdvencedFiltersPrs()


  const columns = [
    {
      name: 'Client Name',
      selector: (row) => row.productItems[0].client.firstName + ' ' + row.productItems[0].client.lastName,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Preset Name',
      selector: (row) => row.productItems[0].preset.name,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      cell: (row) => {
        const statusClass =
          row.status === 'Completed'
            ? 'badge   badge-light-success'
            : row.status === 'Error' || row.status === 'Canceled'
              ? 'badge   badge-light-danger'
              : 'badge   badge-light-warning'

        // return <span className={`${statusClass}`}>{row.status.toString().toUpperCase()}</span>
        return <span className={`${statusClass}`}>{row.status.toString().toUpperCase()}</span>
      },
    },
    // {
    //   name: 'Process Type',
    //   selector: (row) => row.processType,
    //   sortable: true,
    //   filterable: true,
    // },

    // {
    //   name: 'Is Solved',
    //   selector: (row) => row.isSolved,
    //   sortable: true,
    //   filterable: true,
    //   cell: (row) => {
    //     return (
    //       <span className={`text-${row.isSolved === 'Yes' ? 'success' : 'danger'}`}>
    //         {row.isSolved}
    //       </span>
    //     )
    //   },
    // },

    // {
    //   name: 'Mean Coverage',
    //   selector: (row) => row.samples[0].sampleQuality.meanCoverage,
    //   sortable: true,
    //   filterable: true,
    // },
    // {
    //   name: 'Actions',
    //   sortable: true,
    //   filterable: true,
    //   cell: (row) => {
    //     return (
    //       <div className='m-0'>
    //         <a
    //           href='#'
    //           className={clsx('btn btn-sm btn-flex fw-bold')}
    //           data-kt-menu-trigger='click'
    //           data-kt-menu-placement='bottom-end'
    //         >
    //           <i className='fa fa-chevron-down'></i>
    //         </a>
    //         <div className='menu menu-sub menu-sub-dropdown w-100px w-md-200px' data-kt-menu='true'>
    //           <div className='px-7 py-5'>
    //             <div className='fs-5 text-dark fw-bolder'>Run Actions</div>
    //           </div>

    //           <div className='separator border-gray-200'></div>

    //           <div className='px-7 py-5'>
    //             <div className='mb-10'>
    //               <button
    //                 className='btn btn-secondary'
    //                 onClick={() => {
    //                   Swal.fire({
    //                     html:
    //                       'Are you sure to ' + row.status === 'Running'
    //                         ? '  Are you sure to Cancel'
    //                         : ' Are you sure to Delete ' +
    //                         ' <strong>' +
    //                         row?.samples[0].sampleId +
    //                         '</strong> ?',
    //                     icon: 'warning',
    //                     confirmButtonText: 'Yes',
    //                     showCancelButton: true,
    //                     cancelButtonText: 'No',
    //                   }).then(async (result) => {
    //                     if (result.isConfirmed) {
    //                       await RunsService.deleteRun(row?.id)
    //                       Swal.fire(
    //                         row?.samples[0].sampleId + ' ' + row.status === 'Running'
    //                           ? ' Cancelled successfully'
    //                           : ' Removed  successfully!',
    //                         '',
    //                         'success'
    //                       )
    //                       getData()
    //                     }
    //                   })
    //                 }}
    //               >
    //                 {' '}
    //                 <i className='fa fa-trash'></i>
    //                 {row.status === 'Running' ? 'Cancel' : ' Delete '} Run
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   },
    // },

    {
      name: '',
      cell: (row) => {
        return (
          <span
            onClick={() => navigate(`/run-detail-prs/${row?.id || row?.id}`)}
            className='btn  btn-primary my-3 '
          >
            <i className='fa fa-eye mx-2 '></i>
          </span>
        )
      },
    },
  ]

  return (
    <div className='card'>
      <div className='card-body p-4'>
        {tableIsLoading ? (
          <div className='text-center mt-2 mb-2'>
            <Loader />
          </div>
        ) : (
          <>
            <DataTable
              className='table-striped custom-filtered-table-runs'
              pagination
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer',
                    backgroundColor: 'var(--table-bg)',
                    '&:hover': {
                      backgroundColor: '#f3f3f3',
                    },
                  },
                },
              }}
              onRowClicked={(row) => { }}
              columns={columns}
              data={runsDatas}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PRSRunsTable
