import React from 'react'
import {QrReader} from 'react-qr-reader'

const QRCodeScanner = ({qrCodeData, setQrCodeData, setIsStep1Complete, setAccordionStep,handleQrCodeData}) => {
  const handleScan = (result) => {
    if (result) {
      handleQrCodeData(result)
    }
  }

  const handleError = (err) => {}

  return (
    <div style={{position: 'relative', width: '100%'}} className='qr-area'>
      {/* QrReader bileşeni */}
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            handleScan(result.text)
          }

          if (!!error) {
            handleError(error)
          }
        }}
        constraints={{facingMode: 'environment'}}
        style={{ width: '100%', filter: 'grayscale(100%)' }} // Siyah beyaz filtre
        videoContainerStyle={{ transform: 'scaleX(-1)',filter: 'grayscale(100%)' }} // Ayna efektini kapatır

      />

      {/* Köşe kılavuzları */}
      <div
        style={{
          position: 'absolute',
          top: '15%',
          left: '25%',
          width: '50%',
          height: '50%',
          pointerEvents: 'none',
        }}
      >
        {/* Sol Üst Köşe */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '25px',
            height: '25px',
            borderTop: '10px solid #e93645',
            borderLeft: '10px solid #e93645',
          }}
        ></div>

        {/* Sağ Üst Köşe */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '25px',
            height: '25px',
            borderTop: '10px solid #e93645',
            borderRight: '10px solid #e93645',
          }}
        ></div>

        {/* Sol Alt Köşe */}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '25px',
            height: '25px',
            borderBottom: '10px solid #e93645',
            borderLeft: '10px solid #e93645',
          }}
        ></div>

        {/* Sağ Alt Köşe */}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '25px',
            height: '25px',
            borderBottom: '10px solid #e93645',
            borderRight: '10px solid #e93645',
          }}
        ></div>
      </div>
    </div>
  )
}

export default QRCodeScanner
