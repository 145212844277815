import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip'
import somaticService from '../../../service/somatic';

const CarouselSection = ({  items, renderItem, title, getBgColor, getInnerBgColor, resetIndexTrigger }) => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  const prev = () => {
    setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  useEffect(() => {
    if (currentIndex >= items.length) {
      setCurrentIndex(0);
    }
  }, [items, currentIndex]);

  return (
    <div className={`${getBgColor(items[currentIndex])} p-3 rounded position-relative`}> {/* Ana renk */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className="fs-6 fw-medium">{title}</span>
        <div className="d-flex align-items-center gap-4">
          <span className="small">{currentIndex + 1}/{items.length}</span>
          <button
            onClick={prev}
            className="btn btn-sm px-0"
            disabled={items.length <= 1}
          >
            <i className='fa fa-chevron-left'></i>
          </button>
          <button
            onClick={next}
            className="btn btn-sm px-0"
            disabled={items.length <= 1}
          >
            <i className='fa fa-chevron-right'></i>
          </button>
        </div>
      </div>
      <div className={`${getInnerBgColor(items[currentIndex])} p-2 rounded`} style={{
        '--bs-bg-rgb-color': 'unset'
      }}> {/* İçerideki açık renk */}
        {renderItem(items[currentIndex])}
      </div>
    </div>
  );
};

const VariantWidget = ({ variantData }) => {
  const [pmDatas, setPmDatas] = useState([]);
  const [txIsLoading, setTxIsLoading] = useState(true);
  const parseDrugs = (drugString) => {
    return drugString ? drugString.split(',').map(drug => drug.trim()) : [];
  };

  const parseOrphaString = (str) => {
    if (!str) return [];
    return str
      .split('~')
      .filter(entry => entry.trim() !== '') // Boş stringleri filtreler
      .map((entry) => {
        const [number, name, prevalence, inheritance, onset, omim] = entry.split('|');
        return {
          number,
          name: name ? name.split('<br>') : [],
          prevalence: prevalence === '-' ? 'Unknown' : prevalence,
          inheritance: inheritance === '-' ? 'Unknown' : inheritance,
          onset: onset === '-' ? 'Unknown' : onset,
          omim: omim ? omim.split(' ').filter(id => id.trim() !== '' && !id.includes('~')) : []
        };
      });
  };
  const variant = {
    basic: {
      gene: variantData.Gene_refGene?.[0] || 'Unknown',
      aaChange: variantData.aaChange || 'Unknown',
      genomicPos: `${variantData.Chrom || 'Unknown'}:${variantData.Start || 'Unknown'}`,
      variant: `${variantData.Ref || 'Unknown'}>${variantData.Alt || 'Unknown'}`,
      type: variantData.Variant_Type || 'Unknown',
      Oncogenic: variantData.Oncogenic || 'Unknown'
    },
    transcript: {
      id: variantData.tx || 'Unknown',
      exon: variantData.exon || 'Unknown',
      txChange: variantData.txChange || 'Unknown',
    },
    clinical: {
      clinvar: variantData.Clinvar?.trim() || 'Unknown',
      cancerTier: variantData.CancerVar_Tier?.trim() || 'Unknown',
      evidence: variantData.CancerVar_Evidence?.split(',').map(e => e.trim()) || [],
      icgc: variantData.ICGC_Id || 'Unknown'
    },
    metrics: {
      gt: variantData.GT || 'Unknown',
      ad: variantData.AD || 'Unknown',
      af: variantData.AF || 'Unknown',
      dp: variantData.DP || 'Unknown'
    },
    therapeutic: {
      oncogenic: variantData.Oncogenic || 'Unknown',
      level1: parseDrugs(variantData.Level1),
      level2: parseDrugs(variantData.Level2),
      level3A: parseDrugs(variantData.Level3A),
      level3B: parseDrugs(variantData.Level3B),
      level4: parseDrugs(variantData.Level4),
      highestLevel: variantData.HighestLevel || 'Unknown',
      citations: (() => {
        const txC = variantData.TxCitations;
        if (!txC) return [];

        const items = txC.split(';').map(item => item.trim());
        const result = [];
        const links = [];

        items.forEach(item => {
          if (!isNaN(item)) {
            // Eğer id ise
            result.push({ id: parseInt(item, 10), link: `https://www.doi.org/${item}` });
          } else {
            // Eğer link içeriyorsa
            const linkMatch = item.match(/\bhttps?:\/\/\S+/gi);
            if (linkMatch) {
              const link = linkMatch[0]; // İlk linki al
              const title = item.replace(link, '').split('(')[0].trim(); // Linki çıkar, başlık oluştur
              links.push({ id: title || 'Unknown Title', link });
            }
          }
        });

        return [...result, ...links];
      })()
    },
    diseases: parseOrphaString(variantData.Orpha)
  };
  const levelColors = {
    level1: 'bg-level1',
    level2: 'bg-level2',
    level3A: 'bg-level3A',
    level3B: 'bg-level3B',
    level4: 'bg-level4',
    levelR1: 'bg-levelR1',
    levelR2: 'bg-levelR2'
  };

  const innerLevelColors = {
    level1: 'bg-level1-light',
    level2: 'bg-level2-light',
    level3A: 'bg-level3A-light',
    level3B: 'bg-level3B-light',
    level4: 'bg-level4-light',
    levelR1: 'bg-levelR1-light',
    levelR2: 'bg-levelR2-light'
  };
  const getBgColor = (level) => {
    const levelKey = `level${level}`;
    return levelColors[levelKey] || 'bg-info';
  };

  const getInnerBgColor = (level) => {
    const levelKey = `level${level}`;
    return innerLevelColors[levelKey] || 'bg-light';
  };

  const renderTherapeutics = (level) => {
    if(!level) return null;
    const levelKey = `level${level}`;
    const allDrugs = variant.therapeutic[levelKey] || [];
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center text-white">
          <span className="fs-6 fw-medium">Level {level}</span>
          <span className="small">{allDrugs.length} treatments</span>
        </div>
        <div className="d-flex flex-wrap gap-1 mt-2">
          {allDrugs.map((drug, idx) => (
            <span key={idx} className="badge bg-opacity-10 text-white">
              {drug}
            </span>
          ))}
        </div>
      </div>
    );
  };
  const getPm = async (result) => {
    setTxIsLoading(true)
    var reqData = result.filter((item) => {
      return item !== 0
    })
    try {
      var reqRes = await somaticService.getPMDetails(reqData)
      setPmDatas(reqRes.data)
      setTxIsLoading(false)
    } catch (error) {
      console.log(error)
      setTxIsLoading(true)
    }

  }


  useEffect(() => {
    if (variant.therapeutic.citations.length > 0) {
      const ids = variant.therapeutic.citations.filter(citation => !isNaN(citation.id)).map(citation => citation.id);
      if (ids.length > 0) {
        getPm(ids);
      }
    }
    else {
      setTxIsLoading(false)
      setPmDatas([])

    }
  }, [variantData]);

  const renderCitations = (citations) => {
    if(!citations) return null;
    const filteredCitations = citations.filter((citation) => {
      return isNaN(citation.id);
    });
    if (txIsLoading) {
      return (
        <div className={` h-auto text-center  py-2 `}>
          <div className="spinner-grow text-primary m-auto py-2" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )
    }
    if (pmDatas.length === 0 && filteredCitations.length === 0) {
      return (
        <div className="text-muted small">No citations found</div>
      );
    }
    return (
      <div className="d-flex flex-wrap gap-1 mt-2">
        {pmDatas.map((pm, idx) => (
          <a
            key={idx}
            href={`https://www.doi.org/${pm?.doi?.id}`}
            rel="noreferrer"
            target="_blank"
            className="badge bg-secondary bg-opacity-10 text-dark d-inline-block text-truncate"
            style={{
              maxWidth: '100%',
            }}
          >
            - {pm.title}
          </a>
        ))}
        {filteredCitations.map((citation, idx) => (
          <a
            key={idx}
            href={citation.link}
            rel="noreferrer"
            target="_blank"
            className="badge bg-secondary bg-opacity-10 text-dark d-inline-block text-truncate"
            style={{
              maxWidth: '100%',
            }}
          >
            - {citation.id}
          </a>
        ))}
      </div>
    );
  };



  const renderDisease = (disease) => {
     if(!disease) return null;
    return (
      <div className=" p-2 rounded">
        <div className="fs-6 fw-medium text-white mb-2">
          {disease.name?.[0] || 'Unknown'}
        </div>

        <div className="row row-cols-2 g-1 small">
          <span className="text-white">Orpha:</span>
          <a className="font-monospace" href={`${disease.number ? `https://www.orpha.net/en/disease/detail/${disease.number}` : '#'}`} target='_blank' rel="noreferrer">{disease.number || 'Unknown'}</a>

          <span className="text-white">Inheritance:</span>
          <div
            dangerouslySetInnerHTML={{ __html: disease.inheritance || 'Unknown' }} />
          <span className="text-white">Onset:</span>
          <div
            dangerouslySetInnerHTML={{ __html: disease.onset || 'Unknown' }} />
          <span className="text-white">Prevalence:</span>
          <span>{disease.prevalence || 'Unknown'}</span>
        </div>

        {disease.name?.length > 1 && (
          <div className="small mt-2">
            <span className="text-white">Also known as:</span>
            <div className="d-flex flex-wrap gap-1 mt-1">
              {disease.name.slice(1).map((syn, idx) => (
                <span key={idx} className="badge bg-secondary bg-opacity-10 text-white d-inline-block text-truncate" style={{
                  maxWidth: '100%'
                }}>
                  {syn}
                </span>
              ))}
            </div>
          </div>
        )}

        {disease.omim?.length > 0 && (
          <div className="small d-flex  align-items-center gap-3 mt-2">
            <span className="text-white">OMIM</span>
            <div className="d-flex flex-wrap gap-1 mt-2">
              {disease.omim.map((omim, idx) => (
                <a href={`https://www.omim.org/entry/${omim}`} target='_blank' rel='noreferrer' key={idx} className="badge bg-danger bg-opacity-10 font-monospace">
                  {omim}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  };

  const therapeuticLevels = ['1', '2', '3A', '3B', '4', 'R1', 'R2']
    .filter(level => {
      const levelKey = `level${level}`;
      return variant.therapeutic[levelKey] && variant.therapeutic[levelKey].length > 0;
    });

  return (
    <div className="w-100 mw-100 p-3" style={{
      height: 'auto'
    }}>
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          <div className="d-flex align-items-center gap-2 mb-1">
            <span className="fs-3 fw-bold text-body" style={{
              fontStyle: 'italic'
            }}>{variant.basic.gene}</span>
            <span className="fs-5 font-monospace text-primary">{variant.basic.aaChange}</span>
          </div>
          <div className="font-monospace small text-muted drawer-variant-summary" style={{
            maxWidth: '170px'
          }}>
            {variant.basic.genomicPos}{variant.basic.variant}
          </div>
        </div>
        <div className="text-end">
          <div className="badge bg-danger bg-opacity-10 text-danger fs-6 mb-1">
            {variant.basic.Oncogenic}
          </div>
          <div
            className="small text-muted cursor-help"
            data-tooltip-id='tooltip'
            data-tooltip-place='left'
            data-tooltip-content={`${variant.clinical.evidence.join(', ')}`}
            title={`Evidence: ${variant.clinical.evidence.join(', ')}`}
          >
            {variant.clinical.cancerTier}
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="mt-3">
        {/* Therapeutics */}
        {therapeuticLevels.length > 0 && (
          <CarouselSection
            items={therapeuticLevels}
            renderItem={renderTherapeutics}
            title={`Therapeutic Options (${variant.therapeutic.oncogenic})`}
            getBgColor={(level) => getBgColor(level)}
            getInnerBgColor={(level) => getInnerBgColor(level)}
            resetIndexTrigger={variantData}
          />
        )}

        <div className="row mt-3" style={{
          height: 'auto'
        }}>
          <div className="col-md-6">
            {/* Transcript info */}
            <div className="card bg-light " style={{
              height: 'auto'
            }}>
              <div className="card-body py-3" style={{
                height: 'auto'
              }}>
                <div className="d-flex justify-content-between small">
                  <span className="font-monospace">{variant.transcript.id}</span>
                  <span className="text-muted">{variant.transcript.exon}</span>
                </div>
                <div className="font-monospace small text-primary drawer-variant-summary">{variant.transcript.txChange} </div>
              </div>
            </div>

            {/* Metrics */}


          </div>

          <div className="col-md-6">
            <div className="card bg-light mb-3" style={{
              height: 'auto'
            }}>
              <div className="card-body py-2" style={{
                height: 'auto'
              }}>
                <div className="row row-cols-2 g-2 small">
                  <div>
                    <span className="text-muted">GT: </span>
                    <span className="font-monospace">{variant.metrics.gt}</span>
                  </div>
                  <div>
                    <span className="text-muted">AF: </span>
                    <span className="font-monospace">{variant.metrics.af}</span>
                  </div>
                  <div>
                    <span className="text-muted">AD: </span>
                    <span className="font-monospace">{variant.metrics.ad}</span>
                  </div>
                  <div>
                    <span className="text-muted">DP: </span>
                    <span className="font-monospace">{variant.metrics.dp}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <CarouselSection
              items={variant.diseases}
              renderItem={renderDisease}
              title="Disease Associations"
              getBgColor={() => 'bg-level3A '}
              getInnerBgColor={() => 'bg-level3A-light bg-opacity-50'}
              resetIndexTrigger={variantData}
            />
          </div>
          <div className="col">
            {/* make card and render citations */}
            <div className="card bg-light my-3" style={{
              height: 'auto'
            }}>
              <div className="card-body py-2" style={{
                height: 'auto'
              }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="fs-6 fw-medium">Citations</span>
                  <span className="small">{variant.therapeutic.citations.length} citations</span>
                </div>
                {renderCitations(variant.therapeutic.citations)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id='tooltip' />
    </div>
  );
};

export default VariantWidget;
