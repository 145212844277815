import React from 'react'
import Chart from 'react-apexcharts'

const TraidChart = ({data, type, nonNull, prsScore}) => {
  let chartData = data.matches.map((item, index) => ({
    x: index + 1,
    y: item.varWeight,
    genotypeChr: item.genotypeChr,
    genotypePos: item.genotypePos,
    rsId: item.rsId,
    alleleCount: item.alleleCount,
    varRef: item.varRef,
    varAlt: item.varAlt,
  }))


  if (nonNull) {
    chartData = chartData
      .filter((value) => value.alleleCount !== 0 && value.alleleCount !== undefined)
      .sort((a, b) => a.y - b.y)
  }
  else{
      
  chartData = chartData.sort(
    (a, b) => a.genotypeChr - b.genotypeChr || a.genotypePos - b.genotypePos
  )
  chartData = chartData.map((item, index) => ({...item, x: index + 1}))

  }
  const minValue = Math.abs(Math.min(...chartData.map((item) => item.y)))
  const maxValue = Math.abs(Math.max(...chartData.map((item) => item.y)))

  const yAxisMax = Math.max(minValue, maxValue) * 1.1
  const yAxisMin = -yAxisMax

  const series = [
    {
      name: 'βz values',
      data: chartData,
    },
  ]

  const options = {
    chart: {
      id: `${type}-chart`,
    },
    xaxis: {
      type: 'numeric',
      title: {
        text: nonNull ? 'variant sorted by non-null effect' : '',
      },
      min: 0,
      max: chartData.length,
      tickAmount: 10,
      labels: {
        formatter: function (val) {
          return Math.round(val)
        },
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      tickAmount: 5,
      decimalsInFloat: 2,
      title: {
        text: 'βz where PRS = exp(Σ βi z)',
      },
    },
    markers: {
      size: 6,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    title: {
      text: ` #${data.metadata.pgs_id}, PRS ${prsScore}`,
      align: 'center',
      style: {
        fontSize: '16px',
        color: '#263238',
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#71c6fd',
          strokeDashArray: 0,
          label: {
            borderColor: '#71c6fd',
            style: {
              color: '#fff',
              background: '#71c6fd',
            },
          },
        },
      ],
    },
    tooltip: {
      custom: function ({seriesIndex, dataPointIndex}) {
        const dataPoint = chartData[dataPointIndex]
        const value = dataPoint.y
        const chromosomeInfo = `Chr${dataPoint.genotypeChr}`
        const position = dataPoint.genotypePos
        const rsID = dataPoint.rsId
        const change = `${dataPoint.varRef}>${dataPoint.varAlt}`

        return (
          `<div style="padding: 5px; background: #1e3d7c; color: #fff; border-radius: 5px;">` +
          `<strong>${value.toFixed(3)}</strong><br/>` +
          `<span>${chromosomeInfo}</span>.${position}:<span>${change}</span><br/>` +
          `<span style="color: #999;">${rsID}</span>` +
          `</div>`
        )
      },
    },
  }
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Chart options={options} series={series} type={type} width='100%' height='400' />
    </div>
  )
}

export default TraidChart
