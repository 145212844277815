import React from 'react';

const ClientInformation = ({ client }) => {
  return (
    <div className="card px-5">
      <div className="card-header align-items-center border-0 mt-4 py-0 px-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-dark">Client Information</span>
        </h3>
      </div>
      <div className="card-body pt-0 px-2">
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">First Name :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{client.firstName}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Last Name :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{client.lastName}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Date of Birth :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{client.dateOfBirth}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Sex :</div>
            <div className="fw-mormal timeline-content text-muted ps-3 text-capitalize">{client.sex}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ClientInformation };
