/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const user = JSON.parse(localStorage.getItem('user'))
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* <SidebarMenuItem
        to='/new-run'
        icon='gear'
        title={intl.formatMessage({id: 'MENU.NEWRUN'})}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItemWithSub to='#' title='Create Run' hasBullet={false} icon='gear'>
        <SidebarMenuItem
          to='/new-run'
          title={'Sequence'}
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
        <SidebarMenuItem to='/create-prs' title='PRS'  hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='#' title='Runs' hasBullet={false} icon='element-12'>
        <SidebarMenuItem
          to='/runs'
          hasBullet={true}
          title={'Sequence'}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/prs-runs'
          hasBullet={true}
          title={'PRS'}
          fontIcon='bi-app-indicator'
        />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItem
        to='/runs'
        icon='element-12'
        title={intl.formatMessage({id: 'MENU.RUNS'})}
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem to='/table' icon='element-12' title={'Table'} fontIcon='bi-app-indicator' /> */}

      <SidebarMenuItem
        to='/reports'
        icon='document'
        title={'Report Templates'}
        fontIcon='bi-app-indicator'
      />

      {/* <SidebarMenuItem
        to='/afb'
        icon='document'
        logo={'alleleDBlogoWhite2.png'}
        link={'/afb'}
        title={'Allele DB'}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to={process.env.REACT_APP_MORPHEUS_URL}
        token={user.token}
        link={process.env.REACT_APP_MORPHEUS_URL}
        logo={'morpheus-icon.png'}
        icon='document'
        title={'Morpheus'}
        className={''}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Static</span>
        </div>
      </div>

      <SidebarMenuItem
        to='best-practices'
        icon='abstract-26'
        title='Best Practice Filtering'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem to='#' icon='abstract-26' title='Documentation' fontIcon='bi-layers' />
      <SidebarMenuItem to='/faq' icon='abstract-26' title='FAQ' fontIcon='bi-layers' />
      <SidebarMenuItem to='#' icon='abstract-26' title='Version History' fontIcon='bi-layers' />

      {user.type === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/users-list'
            icon='element-12'
            title={intl.formatMessage({id: 'MENU.USERSLIST'})}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/users-run-list'
            icon='element-12'
            title={intl.formatMessage({id: 'MENU.RUNSLIST'})}
            fontIcon='bi-app-indicator'
          />
        </>
      )}

      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
