import React, { useRef } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import './DigitStep.css'

type DigitStepProps = {
  digitInput: string[]
  handleInputChange: (index: number, value: string) => void
  setStep: (step: number) => void
}

const DigitStep: React.FC<DigitStepProps> = ({ digitInput, handleInputChange, setStep }) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  const handleKeyPress = (index: number, value: string) => {

    handleInputChange(index, value)

    // Sadece bir rakam girildiğinde sonraki inputa geç
    if (value && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    // Eğer Backspace tuşuna basılmış ve kutu boş ise önceki inputa odaklan
    if (e.key === 'Backspace' && !digitInput[index] && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
}}>
  <div className="digit-step-container card">
    <div className="digit-step-body card-body">
      <img
        alt="Logo"
        src={toAbsoluteUrl('/media/logos/default-light.svg')}
        className="digit-step-logo"
      />
      <p className="digit-step-text">
        Please enter the 8-digit code that you received with your report.        </p>
      <div className="digit-input-wrapper">
        <div className="digit-input-group">
          {digitInput.slice(0, 4).map((value, index) => (
            <input
              key={`first-${index}`}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleKeyPress(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="digit-input"
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        <span className="digit-divider">-</span>
        <div className="digit-input-group">
          {digitInput.slice(4).map((value, index) => (
            <input
              key={`second-${index}`}
              type="text"
              maxLength={1}
              value={value}
              onChange={(e) => handleKeyPress(index + 4, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index + 4, e)}
              className="digit-input"
              ref={(el) => (inputRefs.current[index + 4] = el)}
            />
          ))}
        </div>
      </div>
      <button className="digit-step-btn btn btn-primary" onClick={() => setStep(2)}>
        Check
      </button>
    </div>
  </div>
    </div >

  )
}

export default DigitStep
