import React, {useState} from 'react'

const TreeItem = ({item, setSelectedFileHandler, selectedFile}) => {
  function humanFileSize(size) {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (item) => {
    // console.log(typeof setSelectedFileHandler)
    if (item.type !== 'directory') {
      return
    }
    //   console.log(obj)
    //   setSelectedFileHandler(obj)
    //   return
    // }
    setIsOpen(!isOpen)
  }
  const setSelectedFile = () => {
    if (item.type !== 'directory') {
      const obj = {
        name: item.name,
        path: item.path,
        size: item.size,
      }
      setSelectedFileHandler(obj)
      return
    }
  }
  return (
    <>
      <tr
        className={` tree-item cursor-pointer ${item.type === 'file' ? 'file-item ' : 'folder-item'} ${
          item?.name === selectedFile?.name ? ' selected ' : ' '
        } `}
        onClick={() => {
          setSelectedFile(item)
          handleToggle(item)
        }}
      >
        <td>
          <span className='toggle'>
            {item.type === 'directory' &&
              (isOpen ? <i className='fa fa-folder-open'></i> : <i className='fa fa-folder'></i>)}
            {item.type === 'file' && <i className='fa fa-file'></i>}
          </span>
          {item.name}
        </td>
        <td className=''>{item.type === 'directory' ? '' : humanFileSize(item.size)}</td>
      </tr>
      {isOpen && (
        <tr className={`sub-tree  `}>
          <td colSpan='3' className='border-none'>
            <table className='w-100 '>
              <tbody>
                {item.contents.map((child, index) => (
                  <TreeItem
                    key={index}
                    selectedFile={selectedFile}
                    setSelectedFileHandler={setSelectedFileHandler}
                    item={child}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  )
}

const FileManager = ({isActive, runFiles, setSelectedFileHandler, selectedFile}) => {
  if (!isActive) {
    return ''
  }
  if (runFiles.length === undefined) {
    runFiles = []
  }
  return (
    <div className='card file-download-modal'>
      <div className='card-body'>
        <table className='tree-table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              {/* <th>Last Modified</th> */}
            </tr>
          </thead>
          <tbody>
            {runFiles?.map((item, index) => (
              <TreeItem
                key={index}
                setSelectedFileHandler={setSelectedFileHandler}
                selectedFile={selectedFile}
                item={item}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FileManager
