import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import DataTable from 'react-data-table-component-with-filter'

const TraidList = ({ selectedTraidList, setSelectedTraidList, runTraits, getAvailableTraits }) => {
  const [filteredData, setFilteredData] = useState(runTraits)
  useEffect(() => {
    setFilteredData(runTraits)
  }, [runTraits])
  const [hideNull, setHideNull] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase()
    setSearchTerm(term)

    const filtered = runTraits.filter((item) => item.name.toLowerCase().includes(term))
    setFilteredData(filtered)
  }
  const columns = [

    {
      name: 'name',
      selector: (row) => row.name,
      sortable: true,
      filterable: false,
      width: '900px',
    },
    {
      name: 'Score',
      selector: (row) => row.prsScore,
      sortable: true,
      filterable: false,
      width: '150px',
      cell: (row) => {
        return (
          <span
            className={clsx('badge', {
              'badge-secondary': row.prsScore === null,
              'badge-danger': row.prsScore !== null && row.prsScore <= 1,
              'badge-warning': row.prsScore !== null && row.prsScore < 1.3 && row.prsScore > 1,
              'badge-success': row.prsScore !== null && row.prsScore >= 1.3,
            })}
          >
            {row.prsScore === null ? (
              'N/A'
            ) : (
              <>
                PRS = {row.prsScore.toFixed(2)}{' '}
                {row.prsScore < 1 ? (
                  <i
                    className="fa fa-chevron-down"
                    style={{
                      color: 'white',
                      marginLeft: 5,
                    }}
                  ></i>
                ) : row.prsScore < 1.3 ? (
                  <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    {`-`}
                  </span>
                ) : (
                  <i
                    className="fa fa-chevron-up"
                    style={{
                      color: 'white',
                      marginLeft: 5,
                    }}
                  ></i>
                )}
              </>
            )}
          </span>

        )
      },
    },
  ]

  const handleChange = ({ selectedRows }) => {
    setSelectedTraidList(selectedRows)
  }
  useEffect(() => {
    if (hideNull) {
      const filtered = runTraits.filter((item) => item.prsScore !== null)
      setFilteredData(filtered)
    }
    else {
      setFilteredData(runTraits)
    }
  }, [hideNull])

  return (
    <div className={`card px-6`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 justify-content-start gap-4 '>
        <h3 className='card-title align-items-start flex-column '>Trait List</h3>
        <div className='py-4 px-2' style={{
          width: '65%',
        }}>
          <input
            type='text'
            className='form-control'
            placeholder='Search Trait...'
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
       
      </div>

      <div className=' align-items-center border-0 mt-4 py-0 px-2 '>
        <div className='py-4 px-2' style={{
        }}>
          <div className='exonic-switch-input form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <label className='font-weight-bold '> Hide N/A </label>
            <input
              className='form-check-input mx-3'
              type='checkbox'
              defaultChecked={hideNull}
              value={hideNull}
              onChange={(e) => setHideNull(e.target.checked)}
              name='exonicSwitch'
            />
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-wrap py-2 px-2 w-100'>
        <DataTable
          className='table-striped custom-filtered-table-prs-run'
          pagination
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer',
                backgroundColor: 'var(--table-bg)',
                '&:hover': {
                  backgroundColor: '#f3f3f3',
                },
              },
            },
          }}
          onRowClicked={(row) => {
            if (row.prsScore !== null) {
              getAvailableTraits(row)
            }

          }}
          columns={columns}
          data={filteredData}
          selectableRows
          onSelectedRowsChange={handleChange}
          expandOnRowClicked
        />
      </div>
    </div>
  )
}

export { TraidList }