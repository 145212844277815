/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import PrsForm from '../../components/NewRun/Forms/PrsForm'

const CreatePRSPage: FC = () => {
  // sampleData,
  // setSampleData,
  // validateSampleId,
  // fileType,
  // qrCodeData,
  // setQrCodeData,
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [qrCodeData, SetQrCodeData] = useState('');
  // const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl()
  return (
    <>
      {/* <div className='upload-file'>
        <div className='card'>
          <div className="card-body d-flex flex-column text-center   gap-10">
            <div className="content-desc  d-flex flex-column justify-content-evenly gap-10 h-100 ">
              <div className="content-create-button ">
                <button className='btn btn-primary' data-bs-toggle='modal'
                  data-bs-target='#PGSFileUploadModal' onClick={() => setIsOpen(true)}> Upload</button>
              </div>
            </div>

          </div>
        </div>
      </div> */}
      {!isCreateMode && (
        <div className='card'>
          <div className="card-body d-flex flex-column text-center   gap-10">
            <div className="content-desc  d-flex flex-column justify-content-evenly gap-10 h-100 ">
              <h1 style={{
                fontSize: '2.50rem',
              }} >Polygenic Risk Score</h1 >

              <div className='content-image    '>
                <img src='/media/gennext-prs-screen.png' height={250} width={800} />
              </div>
              <div className="content-create-button ">
                <button className='btn btn-primary   mt-5' onClick={() => setIsCreateMode(true)} style={{
                  // background: 'linear-gradient(90deg, rgba(233,54,69,1) 0%, rgba(7,20,55,1) 67%, rgba(7,20,55,1) 100%, rgba(232,232,232,1) 255%)'
                }}>  Create PRS Run</button>
              </div>
            </div>

          </div>
        </div>
      )}
      {isCreateMode && <PrsForm qrCodeData={qrCodeData} setQrCodeData={SetQrCodeData} />}
      {/* <PGSFileUploadModal modalOpen={isOpen} /> */}
    </>
  )
}

export { CreatePRSPage }
