import React, { useState, useEffect, useMemo } from 'react'
import { KTIcon } from '../../../helpers'
import { kFormatter } from '../../../../utils'

const PRSNotCompletedWidget = ({ runData, className, nodesList,handleUploadClick, selectedFile, selectedNode, setSelectedNode, setSelectedFile }) => {
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState(1)
  useEffect(() => {
    if (Object.keys(nodesList).length > 0) {
      setLoading(false)
    }
  }, [nodesList])

  const renderWidgetContent = useMemo(() => {
    if (!runData?.productItems) {
      return <div>Loading...</div>
    }

    return (
      <div
        className='shadow-xs card-rounded mx-9 row mb-9 px-6 py-9 position-relative z-index-1 bg-body'
        style={{ marginTop: '-100px' }}
      >
        {/* Render Content Side */}
        <div className={`col-md-${runData.status === 'Running' ? '12' : '6' }`} style={{
          borderRight: '1px solid #e0e0e0',
        }}>
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Genome Assembly Version</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.genomeBuild ?? 'hg37'}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Client Name</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.productItems[0].client.firstName} {runData.productItems[0].client.lastName}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Client Date of Birth</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.productItems[0].client.dateOfBirth}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Client Gender</span>
                <div className='fs-5 text-gray-800 text-hover-primary text-capitalize fw-bold'>
                  {runData.productItems[0].client.sex}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Kit</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.productItems[0].kit.name} - {runData.productItems[0].kit.version}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Preset</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {runData.productItems[0].preset.name}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='text-gray-400 fw-semibold fs-7'>Submission Date</span>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {new Date(runData.submissionDate).toLocaleDateString()}
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <KTIcon iconName='information' className={`fs-5 text-warning ms-1`} />
              </div>
            </div>
          </div>
        </div>

        {runData.status !== 'Running' && (
        <div className={`col-md-6 `}>
          <div className='pb-7'>
            <h2 className='fw-bolder d-flex align-items-center text-dark'>
              Select a Node
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Billing is issued based on your selected account type'
              ></i>
            </h2>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (

            <div className='fv-row'>
              {step === 1 && (
                <div className='row'>
                  {Object.keys(nodesList).map((r) => (
                    <div className='col-lg-4' key={r}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='accountType'
                        value={r}
                        disabled={!nodesList[r].isUp}
                        onChange={(e) => {
                          setSelectedNode(nodesList[e.target.value])
                          setStep(2)
                          document.querySelectorAll('.selected-node').forEach((el) => el.classList.remove('selected-node'))
                        }}
                        id={`kt_create_account_form_account_type_${r}`}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack p-6 m-3 ${selectedNode?.nodeName === nodesList[r].nodeName ? 'selected-node' : ''}`}
                        htmlFor={`kt_create_account_form_account_type_${r}`}
                      >
                        <img src='/media/nodes/SingleComputeNode.svg' alt='node' height={75} />
                        <span className='d-block fw-bold text-start mx-4'>
                          <span className='text-dark fw-bolder d-block fs-4 mb-2'>{nodesList[r].nodeName}</span>
                          <span className='text-dark d-block fs-7 mb-2'>Status: {nodesList[r].isUp ? '✅' : '❌'}</span>
                        </span>
                      </label>
                    </div>
                  ))}
                  <div className='text-danger mt-2'></div>
                </div>

              )}

              {/* File input section, only visible if selectedNode is defined */}
              {step === 2 && (
                <div className='mt-4'>
                  <label htmlFor='fileInput' className='form-label fw-bold'>Upload File:</label>
                  <input
                    type='file'
                    className='form-control'
                    id='fileInput'
                    onChange={(e) => {
                      console.log(e.target.files)
                      setSelectedFile(e.target.files[0])
                    }}
                  />
                  <button
                    className='btn btn-primary mt-8 w-100  col-md-12'
                    onClick={handleUploadClick} // Yükleme butonuna basıldığında tetiklenir
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        )}
      </div>
    )
  }, [runData, nodesList, loading, selectedNode, setSelectedNode])

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-warning`}>
          <div className='d-flex flex-stack'>
            <h2 className='m-0 text-white justify-content-center fw-bold fs-1'>
              Barcode: {runData?.productItems[0].barcode ?? runData?.productItems[0].id}
            </h2>
          </div>
          <div className='d-flex text-center flex-column align-items-center text-white pt-8'>
            <span className='fw-semibold fs-7'>Status</span>
            <span className='fw-bold fs-2 my-2 px-10 py-3 badge badge-light text-warning text-capitalize'>
              {runData?.status === 'ca' && (
                <span className='indicator-progress fs-1 ms-n1' style={{ display: 'inline-block' }}>
                  <span className='spinner-border spinner-border-sm align-middle fs-1 ms-n1 mx-5'></span>
                </span>
              )}
              {runData?.status === 'Waiting for Data' && <i className='fa fa-upload text-warning'></i>}
              <span style={{ marginLeft: 15 }}> {runData?.status}</span>
            </span>
          </div>
        </div>
        {renderWidgetContent}
      </div>
    </div>
  )
}

export { PRSNotCompletedWidget }
