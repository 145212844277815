/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { SoloFamilyFilter } from './FamilyFilter/SoloFamilyFilter'
import { DuoFamilyFilter } from './FamilyFilter/DuoFamilyFilter'
import { TrioFamilyFilter } from './FamilyFilter/TrioFamilyFilter'
import RunsService from '../../service/runs'

const FamilyFilter = ({
  currentRunId,
  sampleId,
  setFamilyFilterDatas,
  familyFilterDatas,
  applyFilterHandler,
  checkHasFilterData,
  filterInfo,
  hasFilterData,
  selectedFamilyFilterButton,
  setSelectedFamilyFilterButton,
  checkHasFilter,
}) => {
  const changeSelectedFamilyFilterButton = async (id) => {
    if (familyFilterDatas.filterType !== id) {
      setSelectedFamilyFilterButton(id)
      checkHasFilter(id)
      if (id === 'solo') {
        setFamilyFilterDatas({ filterType: id, filterDetails: { dominant: true, recessive: false } })
      } else if (id === 'duo') {
        setFamilyFilterDatas({
          filterType: id,
          filterDetails: { dominant: false, recessive: true, caseSelect: null, caseCheckStatus: 0 },
        })
        if (hasFilterData.duo) {
          setFamilyFilterDatas({
            filterType: id,
            filterDetails: {
              dominant: hasFilterData.duo.dominant,
              recessive: hasFilterData.duo.recessive,
              caseSelect: hasFilterData.duo.caseSelect,
              caseCheckStatus: hasFilterData.duo.caseCheckStatus,
            },
          })
        }
      } else {
        setFamilyFilterDatas({
          filterType: id,
          filterDetails: {
            dominant: true,
            recessive: true,
            strict: false,
            fatherSelect: null,
            fatherSelectCheckStatus: 0,
            motherSelect: null,
            motherSelectCheckStatus: 0,
          },
        })
        if (hasFilterData.trio) {
          setFamilyFilterDatas({
            filterType: id,
            filterDetails: {
              dominant: hasFilterData.trio.dominant,
              recessive: hasFilterData.trio.recessive,
              fatherSelect: hasFilterData.trio.fatherSelect,
              fatherSelectCheckStatus: hasFilterData.trio.fatherSelectCheckStatus,
              motherSelect: hasFilterData.trio.motherSelect,
              strict: hasFilterData.trio.strict,
              motherSelectCheckStatus: hasFilterData.trio.motherSelectCheckStatus,
            },
          })
        }
      }
      return
    }
  }
  const [tinyRuns, setTinyRuns] = useState([])

  useEffect(() => {
    RunsService.getTinyRuns(currentRunId).then((data) => {
      const sortedData = data?.data?.sort((a, b) => a.samples[0].sampleId.localeCompare(b.samples[0].sampleId))
      setTinyRuns(sortedData)
    })
  }, [currentRunId])

  useEffect(() => { }, [familyFilterDatas])
  return (
    //  Card Start
    <div className={`card px-6`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Filter</span>
        </h3>
      </div>
      <div className='card-body  gap-5 py-0 px-2 '>
        <div className='familyFilterButtons col-md-12'>
          <span
            className={` mx-2 btn btn-${selectedFamilyFilterButton === 'solo' ? 'primary' : 'secondary'
              }`}
            onClick={() => changeSelectedFamilyFilterButton('solo')}
          >
            {' '}
            Solo
          </span>
          <span
            className={` mx-2 btn btn-${selectedFamilyFilterButton === 'duo' ? 'primary' : 'secondary'
              } `}
            onClick={() => changeSelectedFamilyFilterButton('duo')}
          >
            {' '}
            Duo
          </span>
          <span
            className={` mx-2 btn btn-${selectedFamilyFilterButton === 'trio' ? 'primary' : 'secondary'
              } `}
            onClick={() => changeSelectedFamilyFilterButton('trio')}
          >
            {' '}
            Trio
          </span>
        </div>
        {selectedFamilyFilterButton === 'solo' ? (
          <SoloFamilyFilter
            familyFilterDatas={familyFilterDatas}
            setFamilyFilterDatas={setFamilyFilterDatas}
            sampleId={sampleId}
          />
        ) : selectedFamilyFilterButton === 'duo' ? (
          <DuoFamilyFilter
            familyFilterDatas={familyFilterDatas}
            setFamilyFilterDatas={setFamilyFilterDatas}
            tinyRuns={tinyRuns}
            sampleId={sampleId}
          />
        ) : (
          <TrioFamilyFilter
            familyFilterDatas={familyFilterDatas}
            setFamilyFilterDatas={setFamilyFilterDatas}
            tinyRuns={tinyRuns}
            sampleId={sampleId}
          />
        )}
      </div>
      {/* <div className='apply-filter-button'>
        <button
          className='btn btn-primary btn-sm my-4 '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={applyFilterHandler}
        >
          {' '}
          Apply Filter
        </button>
      </div> */}
    </div>
    //  Card End
  )
}

export { FamilyFilter }
