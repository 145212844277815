// import k8s from "@kubernetes/client-node";
// import request from "request";

import {useEffect, useRef, useState} from 'react'
import {MD5} from 'crypto-js'
import io from 'socket.io-client'
import Peer from 'simple-peer'
import {v4 as uuidv4} from 'uuid'
import NodeService from '../../service/node'
import RunsService from '../../service/runs'
import UserService from '../../service/user'
import {Tooltip} from 'react-tooltip'
import {NewRunWizard} from '../NewRunWizard/NewRunWizard'

const WithDataTransmitNew = ({setIsTransferCompleted, isTransferCompleted}) => {
  const [connectionEstablished, setConnection] = useState(false)
  const [connectionInProgress, setConnectionProgress] = useState(false)
  const [connectionIsFailed, setConnectionIsFailed] = useState(false)

  const [analysts, setAnalysts] = useState()
  const [physicians, setPhysicians] = useState()
  const [institutes, setInstitutes] = useState()
  const [physiciansString, setPhysiciansString] = useState()
  const [institutesString, setInstitutesString] = useState('')
  const transmitIdRef = useRef('')
  const [qrCodeData, setQrCodeData] = useState('')

  const [nodesList, setNodesList] = useState({})
  const [selectedNode, setSelectedNode] = useState({})

  const [analystsString, setAnalystsString] = useState()
  const peerRef = useRef()
  const socketRef = useRef()
  const [isPairedEnd, setIsPairedEnd] = useState(true)

  const [errorData, setErrorData] = useState('')
  const [transferBytesMax, setTransferBytesMax] = useState(0)
  const [transferInProgress, setTransferProgress] = useState(false)
  const [transferBytesCurrent, setTransferBytesCurrent] = useState(0)
  const [userProcessTypes, setUserProcessTypes] = useState({})
  const [canUpload, setCanUpload] = useState(false)
  const [iceServers, setIceServers] = useState([])
  const [enrichmentKits, setEnrichmentKits] = useState({
    company: null,
    filename: null,
    name: null,
    title: null,
  })
  const getUserProcessTypes = async () => {
    const getProcessTypesService = await RunsService.getUserProcessTypes()
    //  getProcessTypesService.data.push({
    //   type: 'prs',
    //   title: 'Polygenic Risk Score',
    //   enrichmentKits: [],
    //  })
    setUserProcessTypes(getProcessTypesService.data)
  }
  const checkCanUpload = async () => {
    const checkUserCanUpload = await UserService.getUserProfile()
    // setUserProcessTypes(getProcessTypesService.data)
    setCanUpload(checkUserCanUpload?.data?.canUpload)
  }
  const getIceServers = async () => {
    const iceServersData = await NodeService.getIceServers()
    setIceServers(iceServersData)
  }

  useEffect(() => {
    checkCanUpload()
    NodeService.getNodeList()
      .then((nodes) => nodes.sort((a, b) => b.isUp - a.isUp))
      .then((r) => Object.fromEntries(r.map((rr) => [MD5(JSON.stringify(rr)).toString(), rr])))
      .then((data) => {
        setNodesList(data)
        setSelectedNode(data[Object.keys(data)[0]])
      })
    getUserProcessTypes()
    getIceServers()
  }, [])
  const fileTypes = [
    {
      label: 'FASTQ',
      type: 'fastq',
    },
    {
      label: 'BAM',
      type: 'bam',
    },
    {
      label: 'VCF',
      type: 'vcf',
    },
  ]

  // const enrichmentKits = [
  //   {
  //     name: 'S31285117',
  //     title: 'SureSelect All Exon V7',
  //     company: 'Agilent',
  //   },
  //   {
  //     name: 'SeqCap_EZ_MedExome',
  //     title: 'SeqCap EZ MedExome',
  //     company: 'Roche',
  //   },
  // ]

  const [selectedProcessType, setSelectedProcessType] = useState('wgs')
  // const [referanceNumber, setReferanceNumber] = useState('')
  const [enrichmentKit, setEnrichmentKit] = useState({})
  const [genomeBuild, setGenomeBuild] = useState('hg38')
  const [fileType, setFileType] = useState('fastq')
  const [enrichmentOrRegion, setEnrichmentOrRegion] = useState('enrichment')

  const [sampleData, setSampleData] = useState([
    {
      id: '',
      name: '',
      files: {},
      regionFile: {name: '.'},
      sampleMeta: {},
    },
  ])
  function Parent() {
    function Child({nodesList, selectedNode, setSelectedNode}) {
      return (
        <>
          {/* {
            <select
              className='form-select'
              value={MD5(JSON.stringify(selectedNode))}
              onChange={(e) => {
                setSelectedNode(nodesList[e.target.value])
              }}
            >
              {Object.keys(nodesList).map((r) => (
                <option key={r} value={r} disabled={nodesList[r].isUp ? false : true}>
                  {nodesList[r].nodeName} - Status: {nodesList[r].isUp ? '✅' : '❌'}
                </option>
              ))}
            </select>
          } */}
          <div className='w-100'>
            <div className='pb-7'>
              <h2 className='fw-bolder d-flex align-items-center text-dark'>
                Select a Node
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Billing is issued based on your selected account type'
                ></i>
              </h2>
            </div>

            <div className='fv-row'>
              <div className='row'>
                {Object.keys(nodesList).map((r) => (
                  <div className='col-lg-4' key={r}>
                    <input
                      type='radio'
                      className={`btn-check `}
                      name='accountType'
                      value={r}
                      disabled={!nodesList[r].isUp}
                      onChange={(e) => {
                        setSelectedNode(nodesList[e.target.value])
                        createTransmit(nodesList[e.target.value])
                        document
                          .querySelectorAll('.selected-node')
                          .forEach((el) => el.classList.remove('selected-node'))
                      }}
                      id={`kt_create_account_form_account_type_${r}`}
                    />
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-stack p-6 m-3 ${
                        selectedNode?.nodeName === nodesList[r].nodeName ? 'selected-node' : ''
                      }`}
                      htmlFor={`kt_create_account_form_account_type_${r}`}
                    >
                      <img
                        src='/media/nodes/SingleComputeNode.svg'
                        alt='node'
                        height={75}
                        className=''
                      />

                      <span className='d-block fw-bold text-start mx-4'>
                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                          {nodesList[r].nodeName}
                        </span>
                        <span className='text-dark d-block fs-7 mb-2'>
                          {' '}
                          Status: {nodesList[r].isUp ? '✅' : '❌'}
                        </span>
                        <span className='text-gray-400 fw-bold fs-6'>
                          {/* If you need more info, please check it out */}
                        </span>
                      </span>
                    </label>
                  </div>
                ))}

                <div className='text-danger mt-2'></div>
              </div>
            </div>
          </div>
        </>
      )
    }
    // Uninitialized state will cause Child to error out
    // Data does't start loading
    // until *after* Parent is mounted
    // Solution:
    // don't render Child until `items` is ready!
    return (
      <div>
        {nodesList && (
          <Child
            nodesList={nodesList}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        )}
      </div>
    )
  }
  function humanFileSize(size) {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  function createTransmit(node = null) {
    transmitIdRef.current = uuidv4()
    const userInfo = getUserInfo()
    setConnectionProgress(true)
    const transmitId = transmitIdRef.current
    // const userInfo = getUserInfo()
    socketRef.current = io.connect(process.env.REACT_APP_EDGE_URL)
    socketRef.current.emit('join transmit', {
      transmitId: transmitId,
      userName: userInfo.userName,
      edgeNode: node ?? userInfo.edgeNode,
    })
    socketRef.current.on('all users', (users) => {
      peerRef.current = createPeer(users[0], socketRef.current.id)
    })

    socketRef.current.on('user joined', (payload) => {
      // console.log("edge conn established")
      peerRef.current = addPeer(payload.signal, payload.callerId)
    })

    socketRef.current.on('receiving returned signal', (payload) => {
      peerRef.current.signal(payload.signal)
      console.log(payload.signal)
      setConnection(true)
      setConnectionProgress(false)
    })

    socketRef.current.on('transmit in progress', () => {
      alert('Transmit is in progress.')
    })
    socketRef.current.on('error', () => {
      setConnectionIsFailed(true)
    })
  }
  function createPeer(userToSignal, callerId) {
    const peer = new Peer({
      initiator: true,
      config: {
        // iceServers: [
        //   {urls: 'stun:stun.l.google.com:19302'},
        //   // {
        //   //     urls: "stun:openrelay.metered.ca:80",
        //   // },
        //   {
        //     urls: 'turn:openrelay.metered.ca:80',
        //     username: 'openrelayproject',
        //     credential: 'openrelayproject',
        //   },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        // ],
        iceServers: iceServers,
      },
      trickle: false,
    })

    peer.on('signal', (signal) => {
      // console.log('callerId: ', callerId)
      // console.log('User to signal: ', userToSignal)
      socketRef.current.emit('sending signal', {userToSignal, callerId, signal})
    })

    peer.on('error', (signal) => {
      setConnectionIsFailed(true)
    })

    // peer.on("data", handleReceivingData);

    return peer
  }

  function addPeer(incomingSignal, callerId) {
    const peer = new Peer({
      initiator: false,
      config: {
        // iceServers: [
        //   {urls: 'stun:stun.l.google.com:19302'},
        //   // {
        //   //     urls: "stun:openrelay.metered.ca:80",
        //   // },
        //   {
        //     urls: 'turn:openrelay.metered.ca:80',
        //     username: 'openrelayproject',
        //     credential: 'openrelayproject',
        //   },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        //   // {
        //   //     urls: "turn:openrelay.metered.ca:443?transport=tcp",
        //   //     username: "openrelayproject",
        //   //     credential: "openrelayproject",
        //   // },
        // ],
        iceServers: iceServers,
      },
      trickle: false,
    })
    peer.on('signal', (signal) => {
      // console.log('signal from ', callerId)
      socketRef.current.emit('returning signal', {signal, callerId})
    })
    peer.on('error', (signal) => {
      setConnectionIsFailed(true)
    })
    // peer.on("data", handleReceivingData);

    peer.signal(incomingSignal)
    setConnection(true)
    return peer
  }
  function getUserInfo() {
    // TODO
    // Retrieve from current user session.
    let currentUser = JSON.parse(localStorage.getItem('user'))

    let dummyData = {userId: currentUser.id, userName: currentUser.username, edgeNode: selectedNode}
    return dummyData
  }
  function fileToObject(file) {
    return {
      name: file.name,
      size: file.size,
      lastModified: file.lastModified,
    }
  }
  function createTranferInfo(createdRunId, sampleId) {
    let transferInfo = {}

    transferInfo.samples = sampleData.map((sample) => {
      if (
        selectedProcessType === 'som-test-tumor-rna' ||
        selectedProcessType === 'som-test-rna' ||
        selectedProcessType === 'som-test-tumor'
      ) {
        return {
          sampleMeta: sample.sampleMeta,
          sampleName: sample.name,
          sampleId: sample.id,
          id: sampleId,
          files: JSON.stringify(
            Object.keys(sample.files).reduce((obj, key, idx) => {
              obj[key] = fileToObject(sample.files[key])
              console.log(typeof obj[key])
              return obj
            }, {})
          ),
          // files: Object.keys(sample.files).reduce((obj, key, idx) => {
          //   obj[key] = fileToObject(sample.files[key])
          //   console.log(typeof obj[key])
          //   return obj
          // }, {}),
          // files: Object.values(sample.files),
        }
      } else {
        return {
          sampleName: sample.name,
          sampleId: sample.id,
          id: sampleId,
          files: JSON.stringify(
            Object.keys(sample.files).reduce((obj, key, idx) => {
              obj[key] = fileToObject(sample.files[key])
              console.log(typeof obj[key])
              return obj
            }, {})
          ),
          // files: Object.values(sample.files),
        }
      }
    })
    transferInfo.processType = selectedProcessType
    transferInfo.refVersion = genomeBuild
    transferInfo.dataType = fileType
    transferInfo.genomeBuild = genomeBuild
    transferInfo.type = 'clientTransferInfoReq'
    // transferInfo.enrichmentKit = enrichmentKit
    transferInfo.enrichmentKit =
      enrichmentKit.name !== undefined
        ? enrichmentKit
        : {
            company: null,
            filename: null,
            name: null,
            title: null,
          }
    transferInfo.jobId = createdRunId
    transferInfo.runId = createdRunId
    transferInfo.userId = getUserInfo().userId
    transferInfo.userHash = getUserInfo().userId
    transferInfo.transferStartTime = new Date()
    // if (selectedProcessType === 'wes') {
    //   transferInfo.enrichmentKit = enrichmentKit
    // }
    return transferInfo
  }
  const onRunCreate = async () => {
    // let requiredFileCount =
    //   selectedProcessType === 'tps' ? 3 : selectedProcessType === 'som-test-tumor-rna' ? 4 : 2

    // if (
    //   selectedProcessType === 'wgs' ||
    //   selectedProcessType === 'wes' ||
    //   selectedProcessType === 'tps'
    // ) {
    //   requiredFileCount = fileType !== 'fastq' ? requiredFileCount - 1 : requiredFileCount
    // }
    // if (
    //   selectedProcessType === 'som-test-rna' ||
    //   selectedProcessType === 'som-test-tumor' ||
    //   selectedProcessType === 'som-test-tumor-rna'
    // ) {
    //   requiredFileCount = fileType === 'fastq' ? 2 : 1
    // }

    // const emptyFieldExist = sampleData.some(
    //   (sample) =>
    //     sample.name === '' ||
    //     sample.id === '' ||
    //     sample.files === '' ||
    //     Object.keys(sample.files).length < requiredFileCount
    // )
    // console.log(emptyFieldExist)
    // if (emptyFieldExist) {
    //   setErrorData('Please Fill in All Fields')
    //   return
    // }

    setErrorData()
    var createdRunInfos = {
      physicians: physicians ?? physiciansString,
      analysts: analysts ?? analystsString,
      processType: selectedProcessType,
      onNode: selectedNode?.nodeName,
      // enrichmentKit:
      //   enrichmentKit.name !== undefined
      //     ? enrichmentKit
      //     : {
      //         company: null,
      //         filename: null,
      //         name: null,
      //         title: null,
      //       },
      samples: sampleData.map((sample) => {
        return {
          sampleName: sample.name,
          sampleId: sample.id,
        }
      }),
      isPairedEnd: true,
      dataType: fileType,
      genomeBuild: 'hg38',
    }
    if (selectedProcessType === 'rnaseq') {
      createdRunInfos.isPairedEnd = isPairedEnd
    }
    if (enrichmentKit.company !== undefined) {
      createdRunInfos.enrichmentKit = enrichmentKit.company + ' - ' + enrichmentKit.title
    }

    var createdRun = await RunsService.createRun(createdRunInfos)

    if (createdRun?.response?.status === 406) {
      setErrorData(createdRun.response.data.message)
      return
    }
    const ti = createTranferInfo(createdRun.data.runId, createdRun?.data?.samples[0].id)
    if (ti) {
      sendFiles(
        createTranferInfo(createdRun?.data?.runId, createdRun?.data?.samples[0].id),
        sampleData[0].files,
        createdRun?.data?.runId
      )
    }
  }

  function sendFiles(transferInfo, files, newRunId) {
    console.log(files)
    // const newArray = Object.keys(files).map((key) => ({ [key]: files[key] }));
    const newArray = Object.values(files)

    setTransferBytesMax(newArray.map((f) => f.size).reduce((a, b) => a + b))
    const peer = peerRef?.current
    peer?.write(JSON.stringify(transferInfo))
    let filesQueue = Array.from(newArray)
    let reader = null
    setTransferProgress(true)
    let stream = filesQueue?.shift()?.stream()
    reader = stream?.getReader()

    async function uploadFile() {
      let ok = true

      do {
        var {done, value} = await reader.read()

        if (done) {
          if (filesQueue.length !== 0) {
            stream = filesQueue.shift().stream()
            reader = stream.getReader()
            await uploadFile()
          } else {
            await RunsService.startRun(newRunId)
            setIsTransferCompleted(true)
          }
          break
        } else {
          if (!!value) {
            for (let i = 0; i < value?.length ?? 0; i = i + 65536) {
              ok = await peer?.write(value.slice(i, i + 65536))
            }
            /*eslint no-loop-func: "off"*/
            setTransferBytesCurrent(
              (transferBytesCurrent) => transferBytesCurrent + value?.length ?? 0
            )
          }
        }
      } while (!done && ok)
      if (!done) {
        peer?.once('drain', uploadFile)
      }
    }

    try {
      uploadFile()
    } catch (error) {
      console.log('err', error)
    }
  }
  let body
  if (connectionIsFailed) {
    body = (
      <h3 className='text-center'>
        Connection Has Failed. Please Reload Page. <br></br>
        <button
          className='btn btn-primary my-3'
          onClick={
            () => window.location.reload() // Sayfayı yenile
          }
        >
          Reload
        </button>
      </h3>
    )
  } else if (!connectionEstablished & !connectionInProgress) {
    body = (
      <div style={{display: 'block'}}>
        <div style={{display: 'block'}}>
          <Parent />
        </div>
        {/* <div style={{display: 'block'}}>
          <button className='btn btn-primary mt-2' onClick={createTransmit}>
            Create transmit
          </button>
        </div> */}
      </div>
    )
  } else if (!connectionEstablished & connectionInProgress) {
    body = (
      <h3 className='text-center'>
        Waiting for edge connection.
        <span className='indicator-progress' style={{display: 'inline-block'}}>
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </h3>
    )
  } else if (connectionEstablished & !transferInProgress) {
    body = (
      <>
        {!canUpload && (
          <div className='alert alert-danger my-5 d-flex align-items-center p-5'>
            <div className='d-flex flex-column'>
              <span>Not permitted on a Demo account.</span>
            </div>
          </div>
        )}
        <NewRunWizard
          sampleData={sampleData}
          setSampleData={setSampleData}
          qrCodeData={qrCodeData}
          setQrCodeData={setQrCodeData}
          selectedProcessType={selectedProcessType}
          setSelectedProcessType={setSelectedProcessType}
          userProcessTypes={userProcessTypes}
          setEnrichmentKits={setEnrichmentKits}
          fileType={fileType}
          isPairedEnd={isPairedEnd}
          onRunCreate={onRunCreate}
          setIsPairedEnd={setIsPairedEnd}
          enrichmentKits={enrichmentKits}
          enrichmentOrRegion={enrichmentOrRegion}
          setEnrichmentOrRegion={setEnrichmentOrRegion}
          setGenomeBuild={setGenomeBuild}
          enrichmentKit={enrichmentKit}
          setEnrichmentKit={setEnrichmentKit}
          institutes={institutes}
          setInstitutes={setInstitutes}
          physicians={physicians}
          setPhysicians={setPhysicians}
          analysts={analysts}
          setAnalysts={setAnalysts}
          isTransferCompleted={isTransferCompleted}
          setPhysiciansString={setPhysiciansString}
          setAnalystsString={setAnalystsString}
          setInstitutesString={setInstitutesString}
          fileTypes={fileTypes}
          setFileType={setFileType}
        />
        {/* <div className=' gap-4 new-run-rti'>
          <div className='row'>
            <div className='col-md-6'>
              <label className='w-100  ml-2 mb-2 input-label'>
                Process Type
                <span
                  className='info-icon mx-2'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content=' Enter the data type you want to process.'
                >
                  <i className='fa fa-info'></i>
                </span>
              </label>
              <OptionsSelect
                className='form-control mb-4 '
                options={userProcessTypes}
                selectRef={selectedProcessType}
                onchange={(e) => {
                  setSampleData([
                    {
                      id: '',
                      name: '',
                      files: {},
                      regionFile: {name: '.'},
                      sampleMeta: {},
                    },
                  ])
                  setSelectedProcessType(e.target.value)
                  const selectedKit = userProcessTypes.find((kit) => kit.type === e.target.value)
                  if (selectedKit) {
                    setEnrichmentKits(selectedKit.enrichmentKits)
                  }
                  console.log(selectedKit, selectedProcessType)
                  console.log(enrichmentKits)
                }}
              />
            </div>
            {(selectedProcessType === 'wgs' ||
              selectedProcessType === 'tps' ||
              selectedProcessType === 'wes') &&
              fileType !== 'fastq' && (
                <div className='col-md-6'>
                  <label className='w-100  ml-2 mb-2 input-label'> Reference Genome </label>

                  <select
                    className={`form-select`}
                    onChange={(e) => {
                      setGenomeBuild(e.target.value)
                    }}
                  >
                    <option value={'hg38'} selected>
                      GRCh38 / hg38
                    </option>
                    <option value={'hg19'}>GRCh37 / hg19</option>
                  </select>
                </div>
              )}
          </div>
          <div className='row'>
            {selectedProcessType === 'prs' && (
              <div className='col-md-12 mb-5'>
                <TagsInput
                  name='refferingPhys'
                  value={institutes}
                  onChange={setInstitutes}
                  placeHolder='Enter Institue'
                  onKeyUp={(e) => {
                    if (!physicians) {
                      setInstitutesString([e.target.value])
                    }
                  }}
                />
              </div>
            )}

            <div className='col-md-6 mb-5'>
              <TagsInput
                name='refferingPhys'
                value={physicians}
                onChange={setPhysicians}
                placeHolder='Enter Physicians (Optional)'
                onKeyUp={(e) => {
                  if (!physicians) {
                    setPhysiciansString([e.target.value])
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
              <TagsInput
                classNames={'mb-4'}
                name='refferingAnaylsts'
                value={analysts}
                onChange={setAnalysts}
                placeHolder='Enter Analysts (Optional)'
                onKeyUp={(e) => {
                  if (!analysts) {
                    setAnalystsString([e.target.value])
                  }
                }}
              />
            </div>
          </div>
          {selectedProcessType !== 'rnaseq' && (
            <>
              <label className='w-100  ml-2 mb-2 input-label'>
                File Type
                <span
                  className='info-icon mx-2'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-html={`<center>Select the file type that you want to process.<br>Do not forget to select the reference genome version if bam/vsf selected.</center>`}
                  data-tooltip-place={'right-start '}
                >
                  <i className='fa fa-info'></i>
                </span>
              </label>
              <OptionsSelect
                className='ontrol mb-4'
                label='File Type'
                options={
                  selectedProcessType === 'som-test-rna' ||
                  selectedProcessType === 'som-test-tumor' ||
                  selectedProcessType === 'som-test-tumor-rna'
                    ? fileTypes.slice(0, 2)
                    : fileTypes
                }
                selectRef={fileType}
                onchange={(e) => {
                  var resettedData = sampleData.map((item) => {
                    return {
                      ...item,
                      files: {},
                    }
                  })
                  console.log(resettedData)
                  setFileType(e.target.value)
                  setSampleData(resettedData)
                }}
              />
            </>
          )}

          {selectedProcessType !== 'wgs' &&
            selectedProcessType !== 'tps' &&
            selectedProcessType !== 'prs' &&
            selectedProcessType !== 'rnaseq' && (
              <label className='w-100 mb-3'>
                <label className='w-100  ml-2 mb-2 input-label'>
                  {' '}
                  Enrichment Kit{' '}
                  <span
                    className='info-icon mx-2'
                    data-tooltip-id='my-tooltip'
                    data-tooltip-html='<center> Select the enrichment kit used in the exome run. If you do not know which kit was used, we recommend using the "Combined Region File" option.<br> This file is created by combining all the kits in the options below.</center>'
                    data-tooltip-place={'right-start '}
                  >
                    <i className='fa fa-info'></i>
                  </span>
                </label>

                <select
                  className={`form-select `}
                  onChange={(e) => {
                    const kit = enrichmentKits.find((k) => k.name === e.target.value)
                    setEnrichmentKit(kit)
                    console.log(kit, enrichmentKit)
                  }}
                  value={userProcessTypes[1].en}
                >
                  <option disabled selected>
                    Enrichment Kit
                  </option>
                  {enrichmentKits?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.company} - {item.title}
                    </option>
                  ))}
                </select>
              </label>
            )}

          {selectedProcessType === 'rnaseq' && (
            <SwitchInput
              label={'Is Single End'}
              onchange={() => setIsPairedEnd(!isPairedEnd)}
              className={'mx-5'}
              labelClass='linkage-label'
            />
          )}

          {selectedProcessType === 'wgs' && (
            <WgsForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'wes' && (
            <WesForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'tps' && (
            <TpsForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'rnaseq' && (
            <RnaseqForm
              sampleData={sampleData}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'som-test-rna' && (
            <SomTestRnaForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'som-test-tumor' && (
            <SomTestTumorForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'som-test-tumor-rna' && (
            <SomTestTumorRnaForm
              sampleData={sampleData}
              fileType={fileType}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          {selectedProcessType === 'prs' && (
            <PrsForm
              sampleData={sampleData}
              setSampleData={setSampleData}
              selectedProcessType={selectedProcessType}
            />
          )}
          <button
            className='btn btn-primary mt-5'
            onClick={onRunCreate}
            disabled={canUpload ? false : true}
            // disabled={fileType === 'bam' || fileType === 'vcf' ? true : false}
          >
            Create Run
          </button>
          {!!errorData && errorData.length > 0 && (
            <div className='alert alert-danger my-5 d-flex align-items-center p-5'>
              <div className='d-flex flex-column'>
                <span>{errorData}</span>
              </div>
            </div>
          )}
        </div> */}
      </>
    )
  } else if (transferInProgress) {
    body = (
      <div>
        <progress
          className='progress-bar w-100 progress'
          id='transferProgress'
          value={transferBytesCurrent}
          max={transferBytesMax}
        />
        {humanFileSize(transferBytesCurrent)} / {humanFileSize(transferBytesMax)}
      </div>
    )
  } else {
    body = (
      <div>
        <button onClick={createTransmit}>Create transmit</button>
        <Parent />
      </div>
    )
  }

  return (
    <div>
      <main className=''>
        <div>{body}</div>
        <Tooltip id='my-tooltip' />
      </main>
    </div>
  )
}

export default WithDataTransmitNew
