import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../../_metronic/helpers'
import { AleleChart } from './AleleChart'
import ColumnChart from './ColumnChart'
import IsppWidget from './IsppWidget'
import VariantWidget from './VariantWidget'
import ClinicalTrialsWidget from './ClinicalTrialsWidget'

const ClinicDrawer = ({ selectedRow, setSelectedRow, allelicWidgetDatas }) => {
  const [activeTab, setActiveTab] = useState(1) // Başlangıçta 1. tab aktif
  const [isAleleChartVisible, setIsAleleChartVisible] = useState(false)
  const [isColumnChartVisible, setIsColumnChartVisible] = useState(false)
  console.log(selectedRow)
  return (
    <div
      id='ClinicDrawer'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#ClinicDrawer_toggle'
      data-kt-drawer-close='#ClinicDrawer_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='ClinicDrawer_header'>
          <h5 className='card-title fw-bold text-gray-600'>
            {activeTab === 1 ? 'Variant Detail Toolbar' : 'Widgets'}
          </h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='ClinicDrawer_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>

        <div className='card-body' id='ClinicDrawer_body'>
          {/* Tab Butonları */}
          <div className='d-flex justify-content-center mb-4'>
            <button
              className={`col-md-4 btn btn-outline-primary mx-2 ${activeTab === 1 ? 'active text-white' : ' border border-dashed rounded'
                }`}
              onClick={() => setActiveTab(1)}
            >
              Detail
            </button>
            <button
              className={`col-md-4  btn btn-outline-primary  mx-2 ${activeTab === 2 ? 'active text-white' : ' border border-dashed rounded'
                }`}
              onClick={() => setActiveTab(2)}
            >
              ISPP
            </button>
            <button
              className={`col-md-4  btn btn-outline-primary  mx-2 ${activeTab === 3 ? 'active text-white' : ' border border-dashed rounded'
                }`}
              onClick={() => setActiveTab(3)}
            >
              ClinTrials
            </button>
          </div>

          {/* Tab İçerikleri */}
          <div className='row h-auto'>
            {activeTab === 1 ? (
              selectedRow.Variant_Type === undefined ? (
                <div className='text-center'>
                  <h3 className='text-gray-400'>Please select a row</h3>
                </div>
              ) : (
                <div className='row h-auto gap-3'>
                  <div className='position-relative'>
                    <span
                      className='hide-alele-chart cursor-pointer '
                      onClick={() => setIsAleleChartVisible(!isAleleChartVisible)}
                      style={{
                        position: 'absolute',
                        right: '25px',
                        zIndex: 1,
                        top: '10px',
                      }}
                    >
                      <i className='fa fa-chevron-down'></i>
                    </span>
                    {!isAleleChartVisible && (
                      <div className={`m-${0} card mx-${0} `} >
                        <h5 className='m-4'> Allelic Fraction</h5>
                      </div>
                    )}
                    {isAleleChartVisible && (
                      <AleleChart
                        selectedRow={selectedRow}
                        DP={selectedRow.DP}
                        setSelectedRow={setSelectedRow}
                        AD={selectedRow.AD}
                        margin={0}
                        allelicWidgetDatas={allelicWidgetDatas}
                        AF={selectedRow.AF}
                      />
                    )}
                  </div>
                  <div className='position-relative '>
                    <span
                      className='hide-alele-chart cursor-pointer '
                      onClick={() => setIsColumnChartVisible(!isColumnChartVisible)}
                      style={{
                        position: 'absolute',
                        right: '25px',
                        zIndex: 1,
                        top: '10px',
                      }}
                    >
                      <i className='fa fa-chevron-down'></i>
                    </span>
                    {!isColumnChartVisible && (
                      <div className={`m-${0} card mx-${0} h-auto`}>
                        <h5 className='m-4'> Allele Frequency</h5>
                      </div>
                    )}
                    {isColumnChartVisible && <ColumnChart selectedRow={selectedRow} margin={0} />}
                  </div>

                  <VariantWidget variantData={selectedRow} />
                  {/* {Object.keys(selectedRow).map((key, index) => (
                    <div
                      key={index}
                      className='col-md-6 d-flex flex-column justify-content-between gap-2 py-2'
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <span className='text-gray-400 fs-7'>{key}</span>
                      <span
                        className='text-gray-800 fs-7'
                        style={{
                          // maxWidth: '100%',
                          overflowY: 'scroll',
                          maxHeight: '50px',
                        }}
                      >
                        {selectedRow[key]}
                      </span>
                    </div>
                  ))} */}
                </div>
              )
            ) : activeTab === 2 ? (
              <div className='text-center'>
                <IsppWidget selectedRow={selectedRow} col={12} />
              </div>
            ) : (
              !selectedRow?.Gene_refGene ? (
                <div className='text-center'>
                  <h3 className='text-gray-400'>Please select a row</h3>
                </div>
              ) : <ClinicalTrialsWidget gene={selectedRow?.Gene_refGene[0]} />
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

export { ClinicDrawer }
