/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../hooks'
import axios from 'axios'
import RunsService from '../../service/runs'
import { v4 as uuidv4 } from 'uuid'

import Swal from 'sweetalert2'

const GeneList = ({
  currentRunId,
  setFamilyFilterGeneList,
  clickedHPOItems,
  setClickedHPOItems,
  selectedHPOItem,
  setSelectedHPOItem,
  geneSymbolListStr,
  setGeneSymbolListStr,
  previewList,
  setPreviewList,
  previewStr,
  setPreviewStr,
  selectedGeneList,
  setSelectedGeneList,
}) => {
  const [phenoType, setPhenoType] = useState('')
  const debouncedPhenoType = useDebounce(phenoType, 500)
  const [genes, setGenes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [savedGenes, setSavedGenes] = useState([])
  const [saveGeneListName, setSaveGeneListName] = useState('')
  // const [clickedHPOItems, setClickedHPOItems] = useState([])
  const [selectedHPOItemStr, setSelectedHPOItemStr] = useState('')

  const [savedGeneList, setSavedGeneList] = useState([])
  const getSavedGenesList = async () => {
    const getList = await RunsService.getGenesList()
    setSavedGeneList(getList.data)
  }

  // const [geneSymbolListStr, setGeneSymbolListStr] = useState('')
  const [geneSymbolList, setGeneSymbolList] = useState([])
  // const [selectedGeneList, setSelectedGeneList] = useState({})
  const [isSearchResultActive, setIsSearchResultActive] = useState(false)

  const searchWrapperRef = useRef(null)

  // useClickOutside(searchWrapperRef, () => {
  //   setIsSearchResultActive(false)
  // })
  function mergeUniqueLists(array) {
    const mergedList = []
    const seen = new Set()

    for (const item of array) {
      if (!seen.has(item)) {
        mergedList.push(item)
        seen.add(item)
      }
    }

    return mergedList
  }
  const hideSearchHPO = () => {
    setPhenoType('')
    setIsSearchResultActive(false)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
        hideSearchHPO()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchResultActive])

  setFamilyFilterGeneList(geneSymbolListStr)
  const getData = () => {
    if (debouncedPhenoType === '') {
      return
    }
    axios
      .get(`https://ontology.jax.org/api/hp/search?q=${debouncedPhenoType}&max=-1`)
      .then((res) => {
        const termsResponse = res?.data?.terms
        if (!!termsResponse && Array.isArray(termsResponse) && termsResponse.length > 0) {
          setGenes(termsResponse.filter((gene) => !savedGenes.includes(gene.id)))
        } else {
          setGenes([])
        }
      })
  }
  useEffect(() => {
    getSavedGenesList()
  }, [])
  useEffect(getData, [debouncedPhenoType, savedGenes])

  const phenoTypeChange = (event) => {
    setPhenoType(event.target.value)
  }

  const getGeneDetail = (id, name) => {
    setIsLoading(true)
    axios
      .get(`https://ontology.jax.org/api/network/annotation/${id}`)
      .then((res) => {
        const geneSymbols = res.data
        if (
          !!geneSymbols?.genes &&
          Array.isArray(geneSymbols?.genes) &&
          geneSymbols?.genes?.length > 0
        ) {
          const cloneSymbolList = [...geneSymbolList]
          const symbols = geneSymbols?.genes?.map((geneSymbol) => geneSymbol.name)
          symbols?.forEach((symbol) => {
            // if (!cloneSymbolList.includes(symbol)) {
            cloneSymbolList.push(symbol)
            // }
          })
          // setGeneSymbolList(cloneSymbolList)
          // setGeneSymbolListStr(cloneSymbolList.join('\n'))
          var set = true
          clickedHPOItems.map((item) => {
            if (item.id === id) {
              set = false
            }
          })
          if (set === true) {
            clickedHPOItems.push({
              id: id,
              name: name,
              list: cloneSymbolList,
              listStr: cloneSymbolList.join('\n'),
            })
            var previewListStr = ''
            clickedHPOItems.map((item) => {
              previewListStr += item.list.join('\n') + '\n'
            })
            var previewListArray = mergeUniqueLists(previewListStr.split('\n'))
            setPreviewStr(previewListArray.join('\n').trim())
            setPreviewList(previewListArray)
            setClickedHPOItems(clickedHPOItems)
          }
          setIsLoading(false)
        } else {
          Swal.fire('No Association Gene Not Found in HPO!', '', 'error')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const saveGeneList = async () => {
    var newId = uuidv4()
    if (saveGeneListName === '' || saveGeneListName === ' ' || saveGeneListName === '  ') {
      Swal.fire(' Please Enter List Name!', '', 'error')

      return
    }
    if (selectedHPOItem.id !== undefined) {
      Swal.fire(' Please Save Your List!', '', 'error')
      return
    }
    const reqData = []
    const customData = {
      sourceType: 'custom',
      sourceId: '0',
      sourceTitle: '',
      isEdited: false,
      genesListTitle: saveGeneListName,
      genesList: geneSymbolListStr.split('\n'),
    }
    var newCheck = false
    if (selectedGeneList.length > 0) {
      if (selectedGeneList[0].genesListTitle === saveGeneListName) {
        customData.genesListId = selectedGeneList[0].genesListId
      } else {
        newCheck = true
        customData.genesListId = newId
      }
    } else {
      newCheck = true
      customData.genesListId = newId
    }
    reqData.push(customData)
    if (clickedHPOItems.length > 0) {
      clickedHPOItems.map((item) => {
        reqData.push({
          sourceType: 'hpo',
          sourceId: item.id,
          sourceTitle: item.name,
          genesListId: newCheck ? newId : selectedGeneList[0].genesListId,
          genesListTitle: saveGeneListName,
          isEdited: false,
          genesList: item.list,
        })
      })
    }
    const saveGeneListReq = await RunsService.saveGeneList(reqData)
    if (saveGeneListReq.status === 200) {
      Swal.fire('Gene List Successfully Saved')
    }
    const savedItem = {
      genesListId: newCheck ? newId : selectedGeneList[0].genesListId,
      genesListTitle: saveGeneListName,
      genesLists: reqData,
      selected: true,
    }
    setSelectedGeneList([savedItem])
    if (newCheck) {
      setSavedGeneList((current) => [...current, savedItem])
    }
  }

  const getSavedGeneListData = (id, name) => {
    if (id === '0') {
      setSelectedGeneList({})
      setSaveGeneListName('')
      setClickedHPOItems([
        {
          id: '1',
          name: ' Add more genes',
          list: [],
          listStr: '',
        },
      ])
      setGeneSymbolListStr('')
      setPreviewList([])
      setPreviewStr('')
      return
    }
    setGeneSymbolListStr('')
    setSelectedHPOItem({})
    setSelectedHPOItemStr('')
    const item = savedGeneList.filter((list) => {
      return list.genesListTitle === id
    })
    const customList = item[0].genesLists.filter((list) => {
      return list.sourceType === 'custom'
    })
    const hpoList = item[0].genesLists.filter((list) => {
      return list.sourceType !== 'custom'
    })
    var hpoListArray = []
    var previewListStr = ''
    hpoList?.map((i) => {
      previewListStr += i.genesList.join('\n') + '\n'
      hpoListArray.push({
        id: i.sourceId,
        name: i.sourceTitle,
        list: i.genesList,
        isEdited: i.isEdited,
        listStr: i.genesList.join('\n'),
      })
    })
    var previewListArray = mergeUniqueLists(previewListStr.split('\n'))
    setPreviewStr(previewListArray.join('\n').trim())
    setPreviewList(previewListArray)
    setSelectedGeneList(item)
    setSaveGeneListName(item[0].genesListTitle)
    setClickedHPOItems(hpoListArray)
    setGeneSymbolListStr(customList[0].genesList.join('\n'))
  }
  const removeHPOItems = (item) => {
    // var sonuc = removeFromTextarea(geneSymbolListStr, item.listStr)
    // setGeneSymbolListStr(sonuc)
    var previewListStr = ''

    var clone = clickedHPOItems.filter((i) => {
      return item.id !== i.id
    })
    clone?.map((item) => {
      previewListStr += item.list.join('\n') + '\n'
    })
    var previewListArray = mergeUniqueLists(previewListStr.split('\n'))
    setPreviewStr(previewListArray.join('\n').trim())
    setPreviewList(previewListArray)
    setClickedHPOItems(clone)
  }
  const showHPOItemsDetail = (item) => {
    if (selectedHPOItem.id === item.id) {
      setSelectedHPOItem({})
      setSelectedHPOItemStr('')
      return
    }
    setSelectedHPOItem(item)
    setSelectedHPOItemStr(item.listStr)
  }
  const saveHpoItem = (item) => {
    var previewList = ''
    let hpoItems = clickedHPOItems.map((i) => {
      if (i.id === item.id) {
        var editted
        if (selectedHPOItemStr === item.listStr) {
          editted = false
        } else {
          editted = true
        }
        // previewList.push(selectedHPOItemStr.split('\n'),)
        previewList += selectedHPOItemStr + '\n'
        return {
          ...i,
          listStr: selectedHPOItemStr,
          list: selectedHPOItemStr.split('\n'),
          isEdited: editted,
        }
      } else {
        previewList += i.listStr + '\n'

        return i
      }
    })
    var previewListArray = mergeUniqueLists(previewList.split('\n'))
    setPreviewStr(previewListArray.join('\n').trim())
    setPreviewList(previewListArray)
    setClickedHPOItems(hpoItems)
    setSelectedHPOItem({})
    setSelectedHPOItemStr('')
  }
  const deleteSavedList = async () => {
    Swal.fire({
      html:
        ' Are you sure to Delete ' +
        ' <strong>' +
        selectedGeneList[0].genesListTitle +
        '</strong> ?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const delReq = await RunsService.deleteGenesList(selectedGeneList[0].genesListTitle)
        if (delReq.status === 200) {
          Swal.fire('Gene List Successfully Deleted')
          const newList = savedGeneList.filter((list) => {
            return list.genesListTitle !== selectedGeneList[0].genesListTitle
          })
          setSavedGeneList(newList)
          setSelectedGeneList({})
          setClickedHPOItems([
            {
              id: '1',
              name: ' Add more genes',
              list: [],
              listStr: '',
            },
          ])
          setSaveGeneListName('')
          setPreviewStr('')
          setPreviewList([])
          setSelectedHPOItem({})
          setSelectedHPOItemStr('')
          setGeneSymbolListStr('')
          setGeneSymbolList([])
          Swal.fire('Removed  successfully!', '', 'success')
        }
      }
    })
  }
  return (
    //  Card Start
    <div className={`card px-6`}>
      {!!isLoading && (
        <div className='spinner'>
          <i className='fa fa-spinner'></i>
        </div>
      )}
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Gene List</span>
        </h3>
      </div>
      <div className='GeneListBody card-body  gap-5 py-0 px-2 '>
        <div className='col-md-6'>
          <div className='paragraphGeneWrap'>
            <select
              onChange={(e) => {
                getSavedGeneListData(e.target.value)
              }}
              // value={selectedGeneList[0]?.genesListTitle ? selectedGeneList[0]?.genesListTitle : 0}
              className='form-select   geneslist-select-list  form-select-solid  w-100'
            >
              <option selected={saveGeneListName === ''} value={0}>
                Choose From List
              </option>
              {savedGeneList
                .sort((a, b) => a.genesListTitle.localeCompare(b.genesListTitle)) 
                .map((item, index) => {
                  return (
                    <option value={item.genesListTitle} selected={item.selected} key={index}>
                      {item.genesListTitle}
                    </option>
                  );
                })}
            </select>
            {selectedHPOItem.id !== undefined && (
              <textarea
                style={{ minHeight: 200 }}
                className='form-control form-control-lg  my-3 form-control-solid'
                rows={4}
                value={selectedHPOItemStr}
                onChange={(e) => {
                  setSelectedHPOItemStr(e.target.value)
                  // setGeneSymbolListStr(e.target.value)
                }}
              ></textarea>
            )}
            {selectedHPOItem.id === undefined && (
              <textarea
                style={{ minHeight: 200 }}
                className='form-control form-control-lg  my-3 form-control-solid'
                rows={4}
                value={previewStr}
                disabled={true}
              ></textarea>
            )}
          </div>

          {!!geneSymbolListStr && <></>}
          {selectedGeneList[0]?.genesListTitle !== undefined && (
            <button className='btn btn-warning btn-sm my-2  ' onClick={deleteSavedList}>
              Delete List
            </button>
          )}

          <button
            className='btn btn-primary btn-sm my-2 pull-right'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            Save Genes
          </button>
          <div className='menu menu-sub menu-sub-dropdown ' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Save Gene List</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
              <input
                type='text'
                onChange={(e) => {
                  setSaveGeneListName(e.target.value)
                }}
                value={saveGeneListName}
                className='form-control w-100'
                placeholder={'List Name'}
              />
              <div className='d-flex my-3 justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-light btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                >
                  Cancel
                </button>

                <button
                  onClick={saveGeneList}
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6'>
          <div className='searchGeneWrap position-relative' ref={searchWrapperRef}>
            <input
              type='text'
              className='form-control form-control-solid w-100'
              placeholder={'Search HPO Terms'}
              value={phenoType}
              onChange={phenoTypeChange}
              onFocus={() => {
                setIsSearchResultActive(true)
                getData()
              }}
            />
            {!!phenoType && (
              <i
                className={`clearSearchGeneInput cursor-pointer fa fa-remove`}
                onClick={() => hideSearchHPO()}
              ></i>
            )}

            {(isSearchResultActive || phenoType !== '') && (
              <div className='searchResultsWrap'>
                {genes.length > 0 && (
                  <ul className='list-group'>
                    {genes
                      .filter((gene) => !savedGenes.includes(gene))
                      .map((item, index) => {
                        if (savedGenes.find((altItem) => altItem.id === item.id)) {
                          return <></>
                        }
                        return (
                          <li
                            key={index}
                            className='list-group-item'
                            style={{
                              cursor: 'unset',
                            }}
                          >
                            {item.name} : <b style={{ fontSize: 10 }}>{item.id}</b>
                            <span
                              className='pull-right text-red'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                hideSearchHPO()
                                getGeneDetail(item.id, item.name)
                              }}
                            >
                              {' '}
                              <i
                                className='fa fa-plus'
                                style={{
                                  color: '#e93645',
                                }}
                              ></i>
                            </span>
                          </li>
                        )
                      })}
                  </ul>
                )}
              </div>
            )}
          </div>
          <div className='saved-hpo-list mt-5'>
            <ul
              className='list-group'
              style={{
                maxHeight: 200,
                overflow: 'auto',
              }}
            >
              {clickedHPOItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    className='list-group-item d-flex justify-content-between align-items-center'
                  >
                    <span
                      className={`fs-${item.id === '1' ? '7 font-weight-bold' : '9'
                        } d-flex  align-items-center`}
                    >
                      {item.id !== '1' && (
                        <span
                          className=' cursor-pointer'
                          onClick={() => {
                            removeHPOItems(item)
                          }}
                          style={{ marginRight: '5px' }}
                        >
                          <i className='fa fa-trash text-danger '></i>
                        </span>
                      )}
                      <span>
                        {item.name} <br></br>{' '}
                        <b style={{ fontSize: 10 }}>{item.id !== '1' ? item.id : ''}</b>
                      </span>
                    </span>
                    <div>
                      {selectedHPOItem.id === undefined && (
                        <span
                          className={`pull-right badge badge-warning cursor-pointer `}
                          onClick={() => showHPOItemsDetail(item)}
                        >
                          <i className={`fa text-white fa-edit`}></i>
                        </span>
                      )}
                      {selectedHPOItem.id === item.id && (
                        <>
                          <span
                            className='badge badge-success pull-right mx-2 cursor-pointer'
                            onClick={() => saveHpoItem(item)}
                          >
                            <i className='fa fa-save text-white'></i>
                          </span>
                          <span
                            className='badge badge-danger pull-right mx-2 cursor-pointer'
                            onClick={() => showHPOItemsDetail(item)}
                          >
                            <i className='fa fa-cancel text-white'></i>
                          </span>
                        </>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='overflow-auto h-75' style={{ maxHeight: 207 }}>
            {savedGenes?.map((gene, i) => (
              <div
                className='mt-2 d-flex align-items-center justify-content-between gap-4 border p-2'
                key={i}
              >
                <div>
                  {gene.name} <br /> <b>{gene.id}</b>
                </div>
                <i
                  onClick={() =>
                    setSavedGenes((prev) => prev.filter((prevGene) => prevGene.id !== gene.id))
                  }
                  className='fa fa-trash cursor-pointer btn btn-primary btn-sm'
                ></i>
              </div>
            ))}
          </div>

          {savedGenes.length > 0 && (
            <button className='btn btn-success btn-sm  my-2'> Save Genes</button>
          )}
        </div>
      </div>
    </div>
    //  Card End
  )
}

export { GeneList }
