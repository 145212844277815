import React from 'react'

const LinkList = ({ data, aleles, calcRiskScore,genomeBuild }) => {
  const tableData = data
    .map((item, index) => {
      return {
        rank: index + 1,
        varWeight: item.varWeight && item.varWeight !== undefined ? item.varWeight.toFixed(3) : 0,
        variant: `Chr${item.genotypeChr}.${item.genotypePos}:g.${item.varRef}>${item.varAlt}`,
        variantLink: `chr=${item.genotypeChr}&from=${item.genotypePos - 50}&to=${item.genotypePos + 50}`,
        genotypePos: Number(item.genotypePos),
        genotypeChr: Number(item.genotypeChr),
        rsID: item.rsId,
        allelesValue: item.genotypeAllele,
        alleleCount: item.alleleCount,
      }
    })
    .filter(item => item.alleleCount !== 0) 
    .sort((a, b) => b.varWeight - a.varWeight); 


    console.log(genomeBuild)
  return (
    <div
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ borderBottom: '2px solid black' }}>
            <th className='text-center'>#</th>
            {/* <th className='text-center'>Rank </th> */}
            <th className='text-center'> β*z</th>
            <th className='text-center'>Variant ({genomeBuild}) </th>
            <th className='text-center'>SNP</th>
            <th className='text-center'> Genotypes</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ccc' }}>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {index + 1}
                {')'}
              </td>
              {/* <td
                style={{
                  padding: '1px 0px',
                }}
              >{`(${row.rank})`}</td> */}
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {row.varWeight}
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                <a
                  href={`https://www.ncbi.nlm.nih.gov/genome/gdv/?id=GCF_000001405.25&${row.variantLink}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {row.variant}
                </a>
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                <a
                  href={`https://www.ncbi.nlm.nih.gov/snp/${row.rsID}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {row.rsID}
                </a>
              </td>
              <td
                style={{
                  padding: '1px 0px',
                }}
              >
                {row.allelesValue}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LinkList
