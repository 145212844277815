/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'

const NewQcSummary = ({
  // roh,
  // totalReads,
  // meanCoverage,
  // medianCoverage,
  // gender,
  currentNode,
  runId,
  sampleId,
  // contamination,
  processType,
  coverage,
  rnaCoverage,
  // readLenght,
}) => {
  const [selectedTab, setSelectedTab] = useState(1)
  const changeQcTab = (id) => {
    setSelectedTab(id)
  }
  const checkData = (data) => {
    if (data) {
      return data
    } else if (data === 0) {
      return 0.0
    } else {
      return 'N/A'
    }
  }

  let coverageItems = Object.keys(coverage)
    .filter(
      (key) =>
        !key.includes('meanCoverage') &&
        !key.includes('gender') &&
        !key.includes('contamination') &&
        !key.includes('medianCoverage') &&
        !key.includes('avgReadLength') &&
        !key.includes('dataSourceType') &&
        !key.includes('roh') &&
        !key.includes('totalReads')
    )
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: coverage[key],
      })
    }, {})
  let coverageItemsRna
  if (processType === 'som-test-tumor-rna' && rnaCoverage) {
    coverageItemsRna = Object.keys(rnaCoverage)
      .filter(
        (key) =>
          !key.includes('meanCoverage') &&
          !key.includes('gender') &&
          !key.includes('contamination') &&
          !key.includes('medianCoverage') &&
          !key.includes('avgReadLength') &&
          !key.includes('dataSourceType') &&
          !key.includes('roh') &&
          !key.includes('totalReads')
      )
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: rnaCoverage[key],
        })
      }, {})
  }
  const intl = useIntl()
  return (
    //  Card Start
    <div className={`card px-5`}>
      <div className='card-header align-items-center border-0 mt-4 py-0 px-2 border '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>{intl.formatMessage({id: 'QCSUMMARY'})}</span>
        </h3>
      </div>

      <div className='card-body py-0 px-0  border'>
        {processType === 'som-test-tumor-rna' && rnaCoverage && (
          <div className='dna-rna-tabs tabs d-flex '>
            <button
              className={`tab btn border rounded-0 w-50  border-bottom-0 ${
                selectedTab === 1 ? '' : 'btn-secondary'
              } `}
              onClick={() => changeQcTab(1)}
            >
              DNA{' '}
            </button>
            <button
              className={`tab btn border rounded-0 w-50  border-bottom-0 ${
                selectedTab === 2 ? '' : 'btn-secondary'
              } `}
              onClick={() => changeQcTab(2)}
            >
              RNA{' '}
            </button>
          </div>
        )}
        {selectedTab === 1 && (
          <div className='timeline-content px-2'>
            <div className='timeline-label my-4'>
              <div className='timeline-item mb-2'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Mean Covarage :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(coverage.meanCoverage) === 'N/A'
                    ? coverage.meanCoverage
                    : coverage.meanCoverage?.toFixed(2)}
                </div>
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item mb-2'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Median Covarage :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(coverage.medianCoverage) === 'N/A'
                    ? coverage.medianCoverage
                    : coverage.medianCoverage?.toFixed(2)}
                </div>
              </div>
              <div className='meanCovaragesItem d-flex justify-content-between text-center '>
                {coverage &&
                  Object.keys(coverageItems)?.map((keyName, index) => (
                    <div
                      className='meanCovaragesItem p-2 d-flex flex-column border w-100'
                      key={index}
                    >
                      <label className='fs-8'> {keyName}</label>
                      <span className='meanCoverageValue'>
                        {' '}
                        {coverageItems[keyName]?.toFixed(2)}
                        {coverageItems[keyName] === null ? '0%' : '%'}{' '}
                      </span>
                    </div>
                  ))}

                {/* <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov5X</label>
              <span className='fs-7'> 95%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov10X</label>
              <span className='fs-7'> 95%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov20X</label>
              <span className='fs-7'> 91%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov30X</label>
              <span className='fs-7'> 87%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov100X</label>
              <span className='fs-7'> 45%</span>
            </div> */}
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Total Read :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(coverage.totalReads)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </div>
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Average Read Length :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <span className=''> {checkData(coverage.avgReadLength)}</span>
                </div>
              </div>
            </div>

            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Contamination :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <span className=''>
                    {checkData(coverage.contamination) === 'N/A'
                      ? coverage.contamination
                      : coverage.contamination?.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedTab === 2 && (
          <div className='timeline-content px-2'>
            <div className='timeline-label my-4'>
              <div className='timeline-item mb-2'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Mean Covarage :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(rnaCoverage.meanCoverage) === 'N/A'
                    ? rnaCoverage.meanCoverage
                    : rnaCoverage.meanCoverage?.toFixed(2)}
                </div>
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item mb-2'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Median Covarage :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(rnaCoverage.medianCoverage) === 'N/A'
                    ? rnaCoverage.medianCoverage
                    : rnaCoverage.medianCoverage?.toFixed(2)}
                </div>
              </div>
              <div className='meanCovaragesItem d-flex justify-content-between text-center '>
                {rnaCoverage &&
                  Object.keys(coverageItemsRna)?.map((keyName, index) => (
                    <div
                      className='meanCovaragesItem p-2 d-flex flex-column border w-100'
                      key={index}
                    >
                      <label className='fs-8'> {keyName}</label>
                      <span className='meanCoverageValue'>
                        {' '}
                        {coverageItemsRna[keyName]?.toFixed(2)}
                        {coverageItemsRna[keyName] === null ? '0%' : '%'}{' '}
                      </span>
                    </div>
                  ))}

                {/* <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov5X</label>
              <span className='fs-7'> 95%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov10X</label>
              <span className='fs-7'> 95%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov20X</label>
              <span className='fs-7'> 91%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov30X</label>
              <span className='fs-7'> 87%</span>
            </div>
            <div className='meanCovaragesItem p-2 d-flex flex-column border'>
              <label className='fs-9'> Cov100X</label>
              <span className='fs-7'> 45%</span>
            </div> */}
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Total Read :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  {checkData(rnaCoverage.totalReads)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </div>
              </div>
            </div>
            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Average Read Length :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <span className=''> {checkData(rnaCoverage.avgReadLength)}</span>
                </div>
              </div>
            </div>

            {/* <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Contamination :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <span className=''>
                    {checkData(rnaCoverage.contamination) === 'N/A'
                      ? rnaCoverage.contamination
                      : rnaCoverage.contamination?.toFixed(2)}
                  </span>
                </div>
              </div>
            </div> */}
            <div className='timeline-label my-4'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5'>
                  Contamination :
                </div>
                <div className='fw-mormal timeline-content text-muted ps-3'>
                  <span className=''>
                    {checkData(rnaCoverage.contamination) === 'N/A'
                      ? rnaCoverage.contamination
                      : rnaCoverage.contamination?.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='card-footer d-flex justify-content-end px-0'>
          <a
            href={`https://${currentNode}.node.${
              process.env.REACT_APP_NODE_URL
            }/quality/${runId}/report?sampleId=${sampleId}&dataSourceType=${
              selectedTab === 1 ? 'dna' : 'rna'
            }`}
            target='_blank'
            rel='noreferrer'
            className='btn btn-primary'
          >
            {' '}
            QC Report<i className='fa fa-arrow-down ml-5'></i>
          </a>
        </div>
      </div>
    </div>
    //  Card End
  )
}

export {NewQcSummary}
