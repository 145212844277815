import React from 'react';

const PrsInformation = ({ genomeBuild, productItem }) => {
  return (
    <div className="card px-5">
      <div className="card-header align-items-center border-0 mt-4 py-0 px-2">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-dark">PRS Information</span>
        </h3>
      </div>
      <div className="card-body pt-0 px-2">
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Genome Build :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{genomeBuild}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Barcode :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{productItem.barcode}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Kit Name :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{productItem.kit.name}</div>
          </div>
        </div>
        <div className="timeline-label my-4">
          <div className="timeline-item">
            <div className="timeline-badge">
              <i className="fa fa-genderless text-primary fs-1"></i>
            </div>
            <div className="timeline-label fw-bold text-gray-800 fs-6 w-auto ml-5">Preset Name :</div>
            <div className="fw-mormal timeline-content text-muted ps-3">{productItem.preset.name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PrsInformation };
