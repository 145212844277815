/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import PRSRunsTable from '../../components/PRSRunsTable'

const PRSRunsPage = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'MENU.RUNS'})}</PageTitle>
      <PRSRunsTable />
    </>
  )
}

export {PRSRunsPage}
