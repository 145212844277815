import axios from './index'

const getAllPrs = async () => {
  const res = await axios.get(`/prs/runs`)
  return res
}
const getPrsDetail = async (id) => {
  const res = await axios.get(`/prs/runs/${id}`)
  return res
}
const getTraits = async (baseUrl) => {
  const res = await axios.get(baseUrl)
  return res
}

const getReport = async (baseUrl) => {
  const res = await axios.get(baseUrl, {
    responseType: 'arraybuffer',
  })
  return res
}

const startRun = async (runId,node) => {
  const response = await axios.get(`/runs/${runId}/start?on_node=${node}`)
  return response.data
}
const uploadPRSFile = async (baseUrl, data, config = {}) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('sampleId', data.sampleId);

  const res = await axios.post(`${baseUrl}`, formData,config);
  return res;
};
const checkBarcode = async (barcode) => {
  const res = await axios.get(`/prs/barcode?barcode=${barcode}`)
  return res
}
const getPresets = async () => {
  const res = await axios.get(`/prs/presets`)
  return res
}
const createPrs = async (data) => {
  const res = await axios.post(`/prs/runs`, data)
  return res
}

const prsService = {
  checkBarcode,
  getPresets,
  getTraits,
  uploadPRSFile,
  getPrsDetail,
  getReport,
  startRun,
  getAllPrs,
  createPrs,
}

export default prsService
